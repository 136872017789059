// eslint-disable-next-line import/no-anonymous-default-export
export default {
  "dashboar.refresh": "Odświeżyć",
  "dashboard.refresh.desc": "Każdy {{time}} {{timeUnit}}",
  "All rights reserved": "Wszelkie prawa zastrzeżone.",
  Dashboard: "Pulpit wyników",
  Planner: "Planista",
  "Resource Planner": "Planista",
  "Business management": "Zarządzanie biznesem",
  Orders: "Zamówienia",
  Invoices: "Faktury",
  Regulations: "Płatności",
  Suppliers: "Dostawcy",
  Rapports: "Raportowanie",
  Search: "Szukaj",
  "Add your Invoice": "Dodaj swoją fakturę lub",
  browse: "Przeglądaj",
  "Show all": "Pokaż wszystko",
  Supplier: "Dostawca",
  "Invoice No.": "Faktury nr.",
  "restriction.form.estimationExecutaion.required.error":
    "Podaj czas realizacji",
  "restriction.form.allowedSession": "Liczba dozwolonych równoległych wykonań",
  "restriction.form.to.error": "Data musi być większa niż",
  "restriction.form.allDays": "Wszystkie dni",
  More: "Więcej",
  Less: "Mniej",
  "Hide filters": "Ukryj filtry",
  "header.kpi.exceptions": "Wyjątki, którymi należy się zająć",
  Mon: "Pon",
  Tue: "Wto",
  Wed: "Śro",
  Thu: "Czw",
  Fri: "Pią",
  Sat: "Sob",
  Sun: "Nie",
  "kpi.parameter.ConsiderBusinessExceptionsAsCompletedItems": "Traktuj wyjątki biznesowe jako ukończone elementy w monitorowaniu wskaźników KPI.",
  "kpi.parameter.ConsiderBusinessExceptionsAsCompletedItems.tooltip": "Wszystkie wyjątki biznesowe będą uznawane za zakończone pomyślnie",
  "Update using historical data": "Aktualizuj przy użyciu danych historycznych",
  "restriction.form.numberInteger.error":
    "Proszę podać poprawną liczbę całkowitą",
  "restriction.form.number.error":
    "Proszę podać liczbę całkowitą",
  "restriction.form.minValue.error": "Wartość musi być większa lub równa 0.",
  "restriction.form.maxValue.error":
    "Liczba cyfr musi być mniejsza lub równa 9.",
  "restriction.form.priority.required.error": "Proszę wpisać numer",
  "restriction.form.priority.minValue.error":
    "Wprowadź wartość większą lub równą 1",
  "restriction.form.priority.maxValue.error":
    "Wprowadź wartość mniejszą lub równą 100",
  "Release date": "Data wydania",
  "Due date": "Termin płatności",
  "Price WT": "Kwota przed opodatkowaniem",
  "Taxe Rate": "stawka VAT",
  Price: "Kwota z podatkiem",
  "Last actions": "Ostatnie działania",
  Today: "Dziś",
  Yesterday: "Wczoraj",
  "Last week": "Zeszły tydzień",
  "no execution in this period": "Nie znaleziono wykonania.",
  "Estimated Execution":
    "Na podstawie kryteriów wyboru szacowany czas realizacji tego procesu wynosi ",
  "Use Historical data": "Użyj danych historycznych?",
  "Choose the period":
    "Wybierz okres, który ma być brany pod uwagę przy obliczaniu szacowanego czasu wykonania dla tego proce :",
  Status: "Status",
  "Execution.time": "Czas egzekucji",
  "Default Alerts": "Alerty domyślne",
  "Choose frequency": "Wybierz częstotliwość",
  "View default alerts": "Zobacz domyślne alerty",
  "Edit default alerts": "Edycja wpisów domyślnych",
  "Delete default alert": "Usuń domyślny alert",
  "Add default alert": "Dodaj domyślny alert",
  "Resources Administration": "Administracja ratunekem",
  "Manage resources": "Zarządzaj ratunekem",
  "Custom Alerts": "Alerty niestandardowe",
  "View custom alerts": "Zobacz alerty niestandardowe",
  "Edit custom alerts": "Edytuj alerty niestandardowe",
  "Delete custom alert": "Usuń alert niestandardowy",
  "Add custom alert": "Dodaj alert niestandardowy",
  Date: "Data",
  "My tasks": "moje faktury",
  "Invoices to verify": "Sprawdzić",
  "Invoices to be checked": "Aby potwierdzić",
  "Invoices to Payers": "Gotowy do zapłaty",
  "Paid invoices": "Płatny",
  "Unpaid invoices": "Nie zapłacony",
  "Total amount of invoices": "Razem faktury",
  "Total VAT amount": "Całkowity podatek VAT",
  "Statistical overview of purchases": "Historia fakturowania",
  "Statistical overview of product sales":
    "Przegląd statystyczny sprzedaży produktów",
  "Total Purchases": "Razem zamówienia zakupu",
  "Total of expenses": "Razem faktury",
  Late: "Zaległy",
  "restriction.form.resources": "Rasoby",
  "restriction.form.priority": "Priorytet",
  "restriction.form.priority.label": "Priorytet Twojego procesu",
  "restriction.form.executionTimeLine": "Harmonogram realizacji",
  "restriction.form.executionDay": "Dzień egzekucji",
  "restriction.form.executionTime": "Czas egzekucji",
  "restriction.form.MinimumExecutionFrequency":
    "Minimalna częstotliwość wykonywania",
  Day: "Dzień",
  Week: "Tydzień",
  "restriction.userGuide.resources":
    "Wskazuje, na których maszynach (Ratunekch) można uruchomić ten proces. Aby dodać kwalifikujące się zasoby, skontaktuj się z zespołem RPA.",
  "restriction.userGuide.priority":
    "Gdy wszystkie umowy SLA są takie same, proces o najwyższym priorytecie (najniższy numer) zostanie wykonany jako pierwszy.",
  "restriction.userGuide.executionTimeLine":
    "Wybierz dni i godziny, w których ten proces może działać.",
  "restriction.userGuide.estimatedExecutionTime":
    "redni czas potrzebny na ukończenie tego procesu.",
  "restriction.userGuide.allowedSession":
    "Liczba równoległych przebiegów dozwolonych dla tego procesu, jeśli dostępna jest wystarczająca liczba licencji i komputerów, aby je uruchomić.",
  "restriction.userguide.frequencyOccurence":
    "Minimalna częstotliwość wykonywania, która jest wymagana do uruchomienia procesu.",
  "restriction.form.from": "Z",
  "restriction.form.to": "Do",
  "restriction.form.estimatedExecutionDuration": "Szacowany czas wykonania",
  "restriction.form.estimatedTime": "Szacowany czas",
  Administrator: "Administrator",
  "administration.status": "Status",
  "administration.company": "Firma",
  "administration.userId": "Identyfikator użytkownika",
  "administration.name": "Nazwa grupy",
  "administration.creationDate": "Data utworzenia",
  "administration.role": "Rola",
  "administration.email": "E-mail",
  "administration.phone": "Telefon",
  "administration.departement": "Dział",
  "administration.subscription": "Subskrypcja",
  "administration.activate.deactivate.user.message":
    "Czy na pewno chcesz aktywować/dezaktywować tego użytkownika?",
  "administration.orchestrator.resources.list.not.available":
    "Nie ma dostępnych ratunek",
  "administration.orchestrator.process.list.not.available":
    "Brak dostępnych procesów",
  "user.add.civility": "Grzeczność",
  "user.add.lastName": "Nazwisko",
  "user.add.firstName": "Imię",
  "user.add.creationDate": "Data utworzenia",
  "user.add.entreprise": "Firma",
  "user.add.subscription": "Subskrypcja",
  "user.add.email": "E-mail",
  "user.add.pass1": "Hasło",
  "user.add.pass2": "Powtórz hasło",
  "user.add.comm.lang": "Język komunikacji",
  "user.add.comm.lang.helperText": "Cała komunikacja i e-maile wysyłane z SmartRoby będą w wybranym języku",
  "New suppliers": "Nowi dostawcy",
  "user.add.process": "Automatyzacja",
  "user.add.availableProcess": "Dostępne automatyka",
  "user.add.affectedProcess": "Dotyczy procesów",
  "user.add.button": "DODAJ UŻYTKOWNIKA",
  "user.add.mfa": "Wymagane OTP",
  "user.add.division.admin": "Administrator działu",
  "user.add.fleet.admin": "Administrator zespół",
  "user.add.division": "Dział",
  "user.status.active": "Włączony",
  "user.modify": "Edytować",
  "process.modify": "Edytować",
  "process.hidden": "Ukryty",
  "user.add": "Dodać",
  "user.button.save": "Dodaj użytkownika",
  "user.button.update": "Aktualizacja",
  "user.button.cancel": "Anulować",
  "user.add.processus": "Automatyzacja",
  "user.status.disable": "Nieaktywny",
  "user.title.add": "Dodaj użytkownika",
  "user.title.details": "Dane użytkownika",
  "user.save.confirmMsg": "Czy na pewno chcesz dodać tego użytkownika?",
  "user.update.confirmMsg":
    "Czy na pewno chcesz zaktualizować dane tego użytkownika?",
  "user.delete.confirmMsg": "Czy na pewno chcesz usunąć tego użytkownika?",
  "user.delete.discard": "Czy na pewno chcesz odrzucić te zmiany?",
  "orchestrator.button.save": "Dodaj Orchestrator",
  "orchestrator.save.confirmMsg":
    "Czy na pewno chcesz dodać tego koordynatora?",
  "orchestrator.update.confirmMsg":
    "Czy na pewno chcesz zaktualizować dane tego koordynatora?",
  "exception.type": "Typ wyjątku",
  "exception.time": "Czas wyjątku",
  Mr: "Pan",
  Mrs: "Pani",
  Ms: "SM",
  "tooltip.action.delete": "Usuwać",
  "tooltip.action.edit": "Edytować",
  "tooltip.action.view": "Pogląd",
  "tooltip.action.add": "Dodać",
  "Visualise Scheduler": "Wizualizuj harmonogram",
  firstName: "Imię",
  lastName: "Nazwisko",
  companyName: "Firma",
  "720 hours/year": "720 godzin/rok",
  "1800 hours/year": "1800 godzin/rok",
  "60 days/year": "60 dzień/rok",
  "120 days/year": "120 dzień/rok",
  "3 months/year": "3 miesiące/rok",
  "5 months/year": "5 miesięcy/rok",
  "user.roles.admin": "Administrator",
  "user.roles.user": "Użytkownik",
  "sorted descending": "posortowane malejąco",
  "sorted ascending": "posortowane rosnąco",
  Invoicing: "Fakturowanie",
  Alerts: "Alerty",
  "User Management": "Zarządzanie użytkownikami",
  "Audit Logs": "Dzienniki audytu",
  Documentation: "Dokumentacja",
  Services: "Usługi",
  "Carte d'identité nationale": "Narodowa karta identyfikacyjna",
  "Driving license": "Prawo jazdy",
  Passport: "Paszport",
  "Green card": "Zielona Karta",
  "Car registration papers": "Dokumenty rejestracyjne samochodu",
  Facture: "Faktura",
  Invoice: "Faktura",
  RIB: "wyciąg z konta bankowego",
  "Purchase order": "Zamówienie",
  "Payroll statemenet": "Zestawienie płac",
  "Avis d'imposition": "Powiadomienie podatkowe",
  "Trigger Details": "Szczegóły wyzwalacza",
  "Start Process": "Rozpocznij proces",
  "Stop Process": "Zatrzymaj się",
  Schedule: "Harmonogram",
  Prioritize: "Priorytet",
  Sheet: "Arkusz",
  Parameters: "Parametry",
  "Processes launcher": "Program uruchamiający automatyka",
  "Drop process": "Upuść tutaj, aby rozpocząć proces",
  "Time saved": "Zaoszczędzony czas",
  "Process overview": "Czas realizacji według procesu",
  "Resources overview": "Czas wykonania według ratunek",
  "Number of executions": "Liczba egzekucji",
  "Latest Transcriptions": "Najnowsze transkrypcje",
  "Break-even": "Rentowność",
  "Return on Investment": "Zwrot z inwestycji",
  "Analyze a new file": "Przeanalizuj nowy plik",
  Receipt: "Paragon fiskalny",
  "EDF proof": "dowód EDF",
  "Click here or drop a file to download":
    "Kliknij tutaj lub upuść plik do pobrania",
  "Loaded File": "Załadowany plik",
  "File preview": "Podgląd pliku",
  Informations: "Informacja",
  "Document name": "Nazwa dokumentu",
  "File content type": "Typ zawartości pliku",
  userId: "identyfikator użytkownika",
  "First name": "Imię",
  "Last name": "Nazwisko",
  CIN: "CIN",
  "Date of Birth": "Data urodzenia",
  "Place of birth": "Miejsce urodzenia",
  "Expiration date": "Termin ważności",
  Address: "Adres zamieszkania",
  Gender: "Płeć",
  CivilStatusNo: "cywilnyStatusNie",
  "Use of APIs": "Zużycie usług",
  "under construction":
    "Ta sekcja jest w budowie, nasz zespół nad nią pracuje. Bądźcie czujni..",
  Overview: "Przegląd",
  Historical: "Historyczny",
  "General Information": "Informacje ogólne",
  "Schedule Information": "Informacje o harmonogramie",
  Description: "Opis",
  processOriginalName: "Oryginalna nazwa procesu",
  Priority: "Priorytet",
  "Start date": "Data rozpoczęcia",
  "End date": "Data zakonczenia",
  Cron: "Cron",
  "FTE Time to Process": "EPC Czas na przetworzenie",
  "Process cost": "Koszt procesu",
  "add.schedule": "Utwórz harmonogram",
  "add.process": "Dodaj proces",
  Reset: "Resetowanie",
  Update: "Aktualizacja",
  Save: "Ratować",
  Cancel: "Anulować",
  Confirm: "Potwierdzać",
  Add: "Dodaj",
  "Are you sure delete this row ?": "Czy na pewno usunąć ten wiersz?",
  "Field must not be empty": "Pole nie może być puste",
  "This process was started successfully":
    "Ten proces został pomyślnie rozpoczęty",
  "The process has been added to the SR Queues.": "Żądane wykonanie zostało dodane do kolejki SR.",
  "An execution trigerred manually on the same resources has already been requested for this process": "Wykonanie uruchomione ręcznie na tym samym robocie zostało już poproszone o ten proces",
  "The process start failed! Please try again later":
    "Rozpoczęcie procesu nie powiodło się! Spróbuj ponownie później",
  ERRORS_EMPTY_FIELD: "Pole nie może być puste.",
  ERRORS_LESS_THAN_MIN_FIELD: "Proszę wprowadzić wartość większą niż {{ limit }}",
  ERRORS_INVALID_DATA: "Proszę podać prawidłowe dane.",
  ERRORS_NO_DATA: "Brak dostępnych danych!",
  WEIGHTS_SUM_MUST_BE_100: "Suma wag musi wynosić 100%",
  "Start date must be less than the end date":
    "Data rozpoczęcia musi być wcześniejsza niż data zakończenia",
  "End date must be greater than the start date":
    "Data zakończenia musi być późniejsza niż data rozpoczęcia",
  "Start time": "Czas rozpoczęcia",
  "End time": "Koniec czasu",
  "How much time does it take an employee to execute this process?":
    "Ile czasu zajmuje pracownikowi wykonanie tego procesu?",
  "How much does the hour cost for an employee executing this task?":
    "Ile kosztuje godzina pracy pracownika wykonującego to zadanie?",
  "How much does this process cost per month ?":
    "Ile ten proces kosztuje miesięcznie?",
  Recurrence: "Nawrót",
  Previous: "Poprzedni",
  "Enter your mail": "Wpisz swój adres e-mail",
  "Confirm your mail": "Potwierdź swoją pocztę",
  "Enter your password": "Wprowadź hasło",
  Login: "Zaloguj sie",
  "Sign up to SmartRoby": "Zarejestruj się w SmartRoby",
  "Go to SmartRoby": "Przejdź do Smartroby",
  "Registration was successfull, you will be redirected":
    "Rejestracja powiodła się, nastąpi przekierowanie",
  "Registration failed": "Rejestracja nieudana",
  Close: "Blisko",
  Open: "Otwórz",
  None: "Brak",
  Retired: "Wycofany",
  "Aws registration token missing": "Brak tokena rejestracji Aws",
  "Email not matching": "E-mail nie pasuje",
  Register: "Zapisz się",
  "You are already signed up": "Jesteś już zapisany",
  "Forgot your password ?": "Zapomniałeś hasła ?",
  "Login or password incorrect !": "Nieprawidłowy login lub hasło !",
  "Copyright © Smart-Roby  All rights reserved.":
    "Copyright © Smart-Roby Wszelkie prawa zastrzeżone.",
  English: "język angielski",
  French: "Francuski",
  Spanish: "hiszpański",
  Polish: "Polski",
  Germany: "Niemiecki",
  Arabic: "Arabski",
  "Process executions": "Egzekucje",
  Ready: "Gotowe",
  "Executed by": "Wykonany przez",
  "you want to modify this data ?": "Czy na pewno chcesz zmodyfikować te dane?",
  Yes: "Tak",
  No: "Nie",
  "Data saved with success": "Dane zapisane z sukcesem",
  "Data not saved": "Dane nie zostały zapisane",
  "Hour(s)": "Godziny)",
  "Day(s)": "Dni)",
  "Week(s)": "Tydzień(ty)",
  "Month(s)": "Miesiące)",
  "Year(s)": "Rok (lata)",
  Every: "Każdy",
  "Choose period": "Wybierz okres",
  "Your input is required": "Twoje dane wejściowe są wymagane",
  "The process": "Automatyzacja",
  "is running, try again when it's completed.":
    "działa, spróbuj ponownie po zakończeniu.",
  "Are you sure you want to execute this process ?":
    "Czy na pewno chcesz wykonać ten proces?",
  Domain: "Domena",
  "Process name": "Nazwa procesu",
  "Process reference": "Odniesienie do procesu",
  "Number used of systems and applications":
    "Liczba używanych systemów i aplikacji",
  "SAP environments (if applicable)": "Środowiska SAP (jeśli dotyczy)",
  Frequency: "Częstotliwość",
  Volume: "Tom",
  "Rule-based": "Oparte na regułach",
  Standardization: "Normalizacja",
  "Structured data": "Dane strukturalne",
  "IT environment": "środowisko IT",
  "Test enabled": "Test włączony",
  "Documentation Readiness": "Gotowość dokumentacji",
  "Authentication mode": "Tryb uwierzytelniania",
  "Process UoM": "Jednostka miary procesu",
  "Time spent for one execution": "Czas poświęcony na jedną egzekucję",
  "Frequency of occurence": "Częstotliwość występowania",
  "Time spent": "Czas spędzony",
  "Time granularity": "Szczegółowość czasu wykonania",
  "Occurence time granularity": "Szczegółowość czasu wystąpienia",
  Occurrence: "Występowanie",
  "Process Exceptions": "Wyjątki procesu",
  "Item Exceptions": "Wyjątki dotyczące przedmiotów",
  "Occurrence driver": "Kierowca zdarzenia",
  "Total time spent in hours per year":
      "Czas (w godzinach) zwrócony do biznesu rocznie",
  "What does an execution represent?": "Co reprezentuje wykonanie?",
  "Re-usability": "Możliwość ponownego wykorzystania",
  "Expected qualitative benefits": "Oczekiwane korzyści jakościowe",
  "Process Stability": "Stabilność procesu",
  "Number of steps": "Liczba kroków",
  "Number of windows": "Liczba okien",
  "Business Rules": "Zasady biznesowe",
  "Business Applications": "Aplikacje biznesowe",
  "Complex functions - Unstructured data / OCR":
    "Złożone funkcje - Dane nieustrukturyzowane / OCR",
  "Complex functions - DataBase": "Funkcje złożone - Baza danych",
  "Complex functions - Powershell": "Złożone funkcje - Powershell",
  "Complex functions - Active Directory":
    "Kompleksowe funkcje - Active Directory",
  "Complex functions - VB Script": "Złożone funkcje - Skrypt VB",
  "Complex functions - Web Services": "Kompleksowe funkcje - Usługi sieciowe",
  "Type of applications - Web application":
    "Rodzaje aplikacji - Aplikacja internetowa",
  "Type of applications - Windows application":
    "Rodzaje aplikacji - aplikacja Windows",
  "Type of applications - Mainframe application":
    "Rodzaje aplikacji — aplikacja Mainframe",
  "Type of applications - Citrix application":
    "Rodzaje aplikacji — aplikacja Citrix",
  "Automation without RPA": "Automatyzacja bez RPA",
  "assessment.Basic documentation is available":
    "Dostępna jest podstawowa dokumentacja",
  "assessment.Few simple applications with a unique ERP instance custom application":
    "Kilka prostych aplikacji ze spersonalizowaną aplikacją instancji unikalnego ERP",
  "assessment.No - Judgment based": "Nie – na podstawie wyroku",
  "assessment.Yes - Based on objective criteria":
    "Tak — na podstawie obiektywnych kryteriów",
  Accounting: "Księgowość",
  Controlling: "Kontrolowanie",
  Other: "Inny",
  Others: "Inni",
  "assessment.Yes - Completely based on objective criteria":
    "Tak — całkowicie w oparciu o obiektywne kryteria",
  "assessment.No - Completely judgment-based":
    "Nie - całkowicie oparte na osądzie",
  "assessment.Completely optimized process, managed at Group level":
    "Całkowicie zoptymalizowany proces, zarządzany na poziomie Grupy",
  "assessment.Largely standardized process, mostly managed at group level":
    "Automatyzacja w dużej mierze standaryzowany, zarządzany głównie na poziomie grupy",
  "assessment.Good level of standardisation, managed regionaly":
    "Dobry poziom standaryzacji, zarządzany regionalnie",
  "assessment.Low degree of standardization and split responsibilities":
    "Niski stopień standaryzacji i podział obowiązków",
  "assessment.Disaggregated process and split responsibilities":
    "Dezagregacja procesu i podział odpowiedzialności",
  "assessment.Yes - Completely structured (e.g. in a form/table/spreadsheet)":
    "Tak — pełna struktura (np. w formie/tabeli/arkuszu kalkulacyjnym)",
  "assessment.No - Completely un-structured e.g. non standardized email":
    "Nie - Całkowicie nieustrukturyzowany, np. niestandardowa poczta e-mail",
  "assessment.Few simple applications (Excel, Outlook, …)":
    "Kilka prostych aplikacji (Excel, Outlook, …)",
  "assessment.Few simple applications with a unique ERP instance or another / custom application":
    "Kilka prostych aplikacji z unikalną instancją SAP lub inną / niestandardową aplikacją",
  "assessment.Few complex / custom applications or ERP instances":
    "Niewiele złożonych / niestandardowych aplikacji lub instancji SAP",
  "assessment.Several complex / custom applications, and a cloud application":
    "Kilka złożonych / niestandardowych aplikacji oraz aplikacja w chmurze",
  "assessment.Large number of applications, (i.e. several ERP instances)":
    "Duża liczba aplikacji (tj. kilka instancji ERP)",
  "assessment.Yes - Data-sets fully available to conduct multiple tests":
    "Tak — zestawy danych w pełni dostępne do przeprowadzania wielu testów",
  "assessment.No - No Data-sets available to conduct multiple tests":
    "Nie — brak dostępnych zestawów danych do przeprowadzenia wielu testów",
  "assessment.A detailed documentation is available":
    "Dostępna jest szczegółowa dokumentacja",
  "assessment.A high level documentation is available":
    "Dostępna jest dokumentacja wysokiego poziomu",
  "assessment.No documentation": "Brak dokumentacji",
  "assessment.Standard authentication mode (ID and password)":
    "Standardowy tryb uwierzytelniania (identyfikator i hasło)",
  "assessment.SSO only authentication mode": "Tryb uwierzytelniania tylko SSO",
  "assessment.Additional authentication required (e.g. USB key)":
    "Wymagane dodatkowe uwierzytelnienie (np. klucz USB)",
  Seconds: "Sekundy",
  Minutes: "Minuty",
  Minute: "Minuta",
  Hours: "Godziny",
  Days: "Dni",
  "unit.manday": "m/d",
  "assessment.Fully Core process compliant": "W pełni zgodny z procesem Core",
  "assessment.Partially Core process compliant":
    "Częściowo zgodny z procesem Core",
  "assessment.Local (zone or country specific)":
    "Lokalny (specyficzny dla strefy lub kraju)",
  "assessment.Major": "Poważny",
  "assessment.High": "Wysoki",
  "assessment.Medium": "Średni",
  "assessment.Low": "Niski",
  "assessment.Minor": "Drobny",
  "assessment.No change is planned": "Żadna zmiana nie jest planowana",
  "assessment.No change is planned in the next 2 years":
    "Żadne zmiany nie są planowane w ciągu najbliższych 2 lat",
  "assessment.Process/system may change in next 2 years":
    "Proces/system może ulec zmianie w ciągu najbliższych 2 lat",
  "assessment.Process/system update planned in next 2 years":
    "Planowana aktualizacja procesu/systemu w ciągu najbliższych 2 lat",
  "assessment.Frequent changes occur in the process":
    "W procesie zachodzą częste zmiany",
  "assessment.1-16": "1-16",
  "assessment.17-45": "17-45",
  "assessment.46-94": "46-94",
  "assessment.More than 94": "Ponad 94",
  "assessment.1-4": "1-4",
  "assessment.5-10": "5-10",
  "assessment.11-20": "11-20",
  "assessment.More than 20": "Więcej niż 20",
  "assessment.Between 2 and 3": "Między 2 a 3",
  "assessment.Between 4 and 10": "Między 4 a 10",
  "assessment.More than 10": "Więcej niż 10",
  "assessment.Between 4 and 5": "Między 4 a 5",
  "assessment.More than 5": "Więcej niż 5",
  "assessment.1": "1",
  true: "PRAWDA",
  false: "fałszywy",
  "planned.executions": "Planowane egzekucje",
  "planner.fullScreenExit": "Salir en modo de pantalla completa",
  "planner.fullScreen": "Abrir el planificador en pantalla completa",
  "Risk management": "Margines ryzyka",
  "planner.risktime": "Czas ryzyka",
  "Latest Exceptions": "Ostatnie wyjątki",
  Process: "Automatyzacja",
  Executing: "Wykonywanie",
  Planned: "Zaplanowany",
  Paused: "Wstrzymane",
  "Rows Per page": "Wiersze na stronę",
  "pagination.of": "z",
  Completed: "Zakończony",
  Running: "Działanie",
  RUNNING: "Działanie",
  "How long will it take an employee to complete this process?":
    "Jak długo zajmie pracownikowi ukończenie tego procesu?",
  "How much does it cost per hour for an employee to perform this task?":
    "Ile kosztuje godzina wykonania tego zadania przez pracownika?",
  unknown: "nieznany",
  Unknown: "Nieznany",
  UNKNOWN: "Nieznany",
  "This Week": "Ostatnie 7 dni",
  "This Month": "Ostatnie 30 dni",
  "This Year": "Ostatnie 365 dni",
  "All time": "Cały czas",
  Discard: "Wyrzucać",
  "Exception Reason": "Przyczyna wyjątku",
  "Exception Workflow Status": "Status",
  "Exception Assignee": "Przypisać do",
  "user has exception item": "To konto użytkownika ma dołączone wyjątki.",
  "Unexpected Error": "Niespodziewany Błąd.",
  READY: "Gotowe",
  TAKEN: "Wyróżniony",
  "users deleted successfully": "Użytkownicy zostali pomyślnie usunięci.",
  "fleet deleted successfully": "Zespół został pomyślnie usunięty.",
  "orchestrator deleted successfully": "program Orchestrator został pomyślnie usunięty.",
  "queue deleted successfully": "Pomyślnie usunięto kolejkę SR",
  "resources.hidden.success": "Udany {{status}} ratunek",
  hidden: "ukryty",
  unhidden: "nieukryty",
  Name: "Nazwa",
  createdBy: "Stworzone przez",
  updatedBy: "Ostatnia modyfikacja przez",
  "Last Update ": "Ostatnio zaktualizowany",
  Feasibility: "Wykonalność",
  Impact: "Uderzenie",
  Roi: "ROI",
  Accepted: "Przyjęty",
  Rejected: "Odrzucony",
  Pending: "W oczekiwaniu",
  PENDING: "W oczekiwaniu",
  DONE: "Zrealizowane",
  DELETED: "Usunięto",
  MISSED: "Pominięty",
  MISSED_P: "W oczekiwaniu (Pominięty)",
  PENDING_SR: "W oczekiwaniu SR",
  EXCEPTION: "Wyjątek",
  "Exception Type": "Typ wyjątku",
  "My cases": "Moje sprawy",
  "Schedule Name": "Nazwa harmonogramu",
  Period: "Okres",
  Exceptions: "Wyjątki",
  Actions: "działania",
  "Loaded Time": "Wczytany czas",
  Reason: "Powód",

  "reporting.processExecutions": "Wykonania",
  "reporting.itemsProcessed": "Przedmioty przetworzone",
  "reporting.itemsCompleted": "Przedmioty ukończone",
  "reporting.successRate": "Wskaźnik sukcesu przedmiotów",
  "reporting.exceptionRate": "Wskaźnik wyjątków przedmiotów",
  "reporting.itemsBusinessExceptions": "Wyjątki biznesowe dla pozycji",
  "reporting.itemsSystemExceptions": "Wyjątki systemowe przedmiotów",

  "Exception Time": "Czas wyjątku",
  "How much time does it take an employee to execute an Item in this process? (in minutes)":
    "Ile czasu zajmuje pracownikowi wykonanie pozycji w tym procesie? (w minutach)",
  "How much does this Process cost to maintain per Year?":
    "Ile kosztuje utrzymanie tego procesu rocznie?",
  "Cost Shared": "Koszt wspólny",
  Weight: "Waga",
  "Allocation Amount": "Kwota przydziału",
  "Time Saved per Execution": "Czas zaoszczędzony na wykonanie:",
  "Save ": "Zapisz",
  "Cancel ": "Anuluj",
  "Time Saved per Item": "Czas zaoszczędzony na przedmiocie:",
  "Time saved for each completed item of this process (in minutes)": "Czas zaoszczędzony na każdej ukończonej pozycji tego procesu (w minutach)",
  recurring: "Powtarzający się",
  "one-shot": "Jeden strzał",
  exclusive: "Wyłącznie",
  "Cost Type": "Rodzaj kosztów",
  shared: "Wspólne",
  "Cost Amount": "Koszt Kwota",
  "Add tag": "Dodaj tag",
  "Edit tag": "Edytuj tag",
  "Delete tag": "Usuń tag",
  "Tags Module": "Tagi",
  Type: "Typ",
  "Add cost": "Dodaj koszt",
  "Cost Allocation": "Alokacja kosztów",
  Category: "Kategoria",
  "Delete Item": "Usuń element",
  "Delete cost": "Usuń koszt",
  "Edit cost": "Edycja kosztów",
  "Are you sure you want to delete this cost ?": "Czy na pewno chcesz usunąć ten koszt",
  "This cost is shared with multiple processes, if you would like to remove the cost allocated to this process individually, please edit the cost and remove the processes from the Sharing paramteres. If you are sure you want to delete this cost from all processes, proceed with the delete.":
  "Ten koszt jest współdzielony z wieloma procesami, jeśli chcesz usunąć koszt przypisany do tego procesu indywidualnie, edytuj koszt i usuń automatyka z paramterów Udostępnianie. Jeśli jesteś pewien, że chcesz usunąć ten koszt ze wszystkich procesów, przejdź do usuwania.",
  "FTE Cost": "EPC Koszt:",
  "Cost Configuration": "Konfiguracja kosztów",
  "Cost Name": "Nazwa kosztu",
  "FTE hourly cost": "Koszt godzinowy w przeliczeniu na pełne etaty",
  " Time saved for each execution of this process excluding the time saved from every item (in minutes)":
  " Czas zaoszczędzony na każdym wykonaniu tego procesu z wyłączeniem czasu zaoszczędzonego z każdej pozycji (w minutach)",
  "Cost Category": "Kategoria kosztów",
  "Are you sure you want to update the parameters ?":
    "Czy na pewno chcesz zaktualizować parametry?",
  "Are you sure you want to create this schedule ?":
    "Czy na pewno chcesz utworzyć ten harmonogram?",
  "Are you sure you want to update this schedule ?":
    "Czy na pewno chcesz zaktualizować ten harmonogram?",
  "The schedule was created successfully":
      "Twoje żądanie zostało wysłane do BP i harmonogram zostanie wkrótce utworzony",
  "Failed to create the schedule! Please try again later":
    "Nie udało się utworzyć harmonogramu! Spróbuj ponownie później",
  "Failed to update parameters": "Nie udało się zaktualizować parametrów",
  "Are you sure you want to disable this schedule ?":
      "Czy na pewno chcesz wyłączyć ten harmonogram ?",
  "Are you sure you want to enable this schedule ?":
       "Czy na pewno chcesz włączyć ten harmonogram ?",
  "button.deactivate.confirm":
       "Wyłączyć",
  "button.activate.confirm":
       "Aktywować",
  hours: "godziny",
  Once: "Raz",
  Hourly: "Cogodzinny",
  Daily: "Codzienny",
  Weekly: "Co tydzień",
  Monthly: "Miesięczny",
  Yearly: "Rocznie",
  Trimestrial: "Kwartalny",
  Quarterly: "Kwartalny",
  "Start Date": "Data rozpoczęcia",
  "Are you sure you want to save the changes ?":
    "Czy na pewno chcesz zapisać zmiany?",
  "Failed to modify schedule": "Nie udało się zmodyfikować harmonogramu",
  "Are you sure you want to disregard the changes ?":
    "Czy na pewno chcesz zignorować zmiany?",
  "Changes disregarded": "Zmiany zignorowane",
  "Are you sure you want to delete this exception ?":
    "Czy na pewno chcesz ukryć ten wyjątek?",
  "Are you sure you want to show this exception ?":
    "Czy na pewno chcesz pokazać ten wyjątek?",
  "Are you sure you want to delete these exception ?":
    "Czy na pewno chcesz usunąć te wyjątki?",
  "Exception Deleted Successfully": "Wyjątek został pomyślnie ukryty!",
  "Exception Restored Successfully": "Pomyślnie przywrócono wyjątek!",
  "Exceptions Deleted Successfully": "Wyjątek został pomyślnie ukryty!",
  "Are you sure you want to delete": "Czy na pewno chcesz się ukryć",
  "Are you sure you want to show X exceptions?":
    "Czy na pewno chcesz pokazać {{count}} wyjątków ?",
  exceptions: "wyjątki",
  "exceptions successfully deleted": "wyjątki z powodzeniem ukryte",
  "X exceptions successfully restored":
    "{{count}} wyjątki przywrócone z powodzeniem",
  Submitted: "Złożony",
  Draft: "Projekt",
  "Not submitted": "Nie przesłano",
  "Are you sure?": "Jesteś pewny?",
  "You won't be able to revert this!": "Nie będziesz w stanie tego cofnąć!",
  "Yes, submit it!": "Tak, prześlij to!",
  "Yes, save it!": "Tak, zachowaj to!",
  "Submit later": "Zatwierdź później",
  Back: "Z powrotem",
  Result: "Wynik",
  "Impact/Feasibility Matrix": "Macierz wpływu/wykonalności",
  next: "Następny",
  "Red zone": "czerwona strefa",
  "Impact and feasibility are limited": "Automatyzacja jest złożony do zautomatyzowania przy minimalnym wpływie",
  "The process is likely to be reprioritized": "Zaleca się zdegradowanie tego procesu, chyba że inne kryteria są brane pod uwagę podczas arbitrażu",
  "Yellow zone": "Strefa żółta",
  "Here either the impact or the feasibility is relevant": "Wpływ automatyzacji lub jej wykonalność wykazuje duży potencjał",
  "Depending on the case and arbitration, the process could be deprioritized": "Priorytet tego procesu jest do arbitrażu z innymi kryteriami",
  "Green zone": "Strefa zielona",
  "Both impact and feasibility show some great signs": "Zaleca się priorytet dla tego procesu",
  "The process is likely to be prioritized": "Zaleca się priorytet dla tego procesu",
  "Saving (per year)": "Oszacowanie zysków (rocznie)",
  "FTE Gains": "Zyski FTE",
  "FTE Costs": "Koszty etatu",
  "Total in $": "Razem w $",
  "Developement costs": "Oszacowanie kosztów rozwoju (wm/d)",
  Developer: "Deweloper",
  "Business analyst": "Analityk Biznesowy",
  "Running costs (per year)": "Koszty bieżące (rocznie)",
  "Running costs in $": "Koszty bieżące w $",
  "Payback period (year)": "Okres zwrotu (rok)",
  "Yes, accept it": "Tak, zaakceptuj to",
  "Yes, reject it": "Tak, odrzuć to",
  "Yes, delete it": "Tak, usuń to",
  NAME: "NAZWA",
  "CREATED BY": "STWORZONE PRZEZ",
  "Date created": "Stworz Date",
  "LAST UPDATE": "Ostatnio zaktualizowany",
  Edit: "Modyfikator",
  Exit: "Wyjście",
  "Home - RPA Qualification Tool By Novelis innovation":
    "Strona główna - Narzędzie do kwalifikacji RPA Innowacje Novelis",
  "No opportunities found !": "Nie znaleziono możliwości !",
  "create.opportunity": "Utwórz okazję",
  "No cases found !": "Nie znaleziono przypadków !",
  "Tool for discovering, evaluating and classifying business processes":
    "Narzędzie do odkrywania, oceny i klasyfikacji procesów biznesowych",
  "Sign in": "Zaloguj się",
  "Invalid email address": "Niepoprawny adres email",
  "Email Address": "Adres e-mail",
  "Password is required": "Wymagane jest hasło",
  "Password must contains at least 8 characters":
    "Hasło musi zawierać co najmniej 8 znaków",
  Password: "Hasło",
  "No network": "Brak sieci",
  "Please verify your internet connection and try again !":
    "Zweryfikuj swoje połączenie internetowe i spróbuj ponownie!",
  OK: "OK",
  "New process": "Nowy proces",
  "Edit process": "Aby edytować proces",
  "Page not found": "Strona nie znaleziona",
  "Sorry, the page you are looking for can't be found !":
    "Przepraszamy, nie można znaleźć strony, której szukasz!",
  Reject: "Odrzucić",
  Submit: "Składać",
  "rpaQual.action.submit": "Uprawomocnić",
  "assessment.submit": "Składać",
  Accept: "Zaakceptować",
  Delete: "Usuwać",
  "priority.error": "Wartość już istnieje, wybierz inną wartość.",
  "priority.error.unhide":
    "Wartość priorytetu tego procesu już istnieje. Twój proces zostanie odkryty bez ustawionego priorytetu.",
  "rpaqual.submit": "Uprawomocnić",
  "Select a Department": "Wybierz dział",
  "name your process": "nazwij swój proces",
  "Select a resources": "Wybierz ratunek",
  "describe briefly your process": "opisz krótko swój proces",
  "What is the value that would drive a round of process executions":
    "Jaka jest wartość, która doprowadziłaby do rundy realizacji?",
  "Select if process is rule-based":
    "Wybierz, jeśli proces jest oparty na regułach",
  "Select if process is standardized":
    "Wybierz, jeśli proces jest ustandaryzowany",
  "Select if data used in the process is structured ":
    "Wybierz, czy dane wykorzystywane w procesie są ustrukturyzowane",
  "Select the sentence that fits the most with the context of your process":
    "Wybierz zdanie, które najbardziej pasuje do kontekstu Twojego procesu",
  "Select if testing will be possible":
    "Wybierz, czy testowanie będzie możliwe",
  "the documentation level that fits the most with the context of your process":
    "poziom dokumentacji, który najlepiej pasuje do kontekstu Twojego procesu",
  "what authentication mode is applied for the systems in scope":
    "jaki tryb uwierzytelniania jest stosowany dla systemów objętych zakresem",
  "Time required for a single but complete process execution":
    "Czas potrzebny na wykonanie pojedynczego, ale kompletnego",
  "Time granularity of the above mentionned time":
    "Ziarnistość czasowa wyżej wymienionego czasu",
  "Weekly=52, Monthly=12, Daily=220.Details of your calculations in the comment section":
    "np: Tygodniowo=52, Miesięcznie=12, Dziennie=220",
  "This field is populated automatically from the details time spent below":
    "To pole jest wypełniane automatycznie na podstawie informacji o czasie spędzonym poniżej",
  "Assess re-usability to the best of your knowledge":
    "Oceń możliwość ponownego wykorzystania zgodnie ze swoją najlepszą wiedzą",
  "Explain what are the benefits expected, e.g. better quality":
    "Wyjaśnij, jakie są oczekiwane korzyści, np. lepsza jakość",
  "Select the number of steps": "Wybierz liczbę kroków",
  "number of screens that change during the execution ":
    " liczba ekranów zmieniająca się w trakcie wykonywania",
  "number of  the business rules governing the process":
    "Wybierz liczbę reguł biznesowych regulujących proces",
  "number of business applications involved in the process":
    "liczba aplikacji biznesowych zaangażowanych w proces",
  "whether unstructured data or any OCR processing will be processed":
    "czy będą przetwarzane dane nieustrukturyzowane lub jakiekolwiek przetwarzanie OCR",
  "communication with a database to establish or any sql commands will be executed":
    "komunikacja z bazą danych w celu nawiązania lub wykonanie jakichkolwiek poleceń sql",
  "if any Powershell script to install or any powershell code to execute in the command line":
    "jeśli jakiś skrypt Powershell do zainstalowania lub dowolny kod powershell do wykonania w wierszu poleceń",
  "if the resourcess will have a Windows account and communicate by email or Teams":
    "czy zasoby będą miały konto Windows i będą komunikować się przez e-mail lub Teams",
  "if there is a need to create VB scripts or run macros directly on blue prism ":
    "czy istnieje potrzeba tworzenia skryptów VB lub uruchamiania makr bezpośrednio na niebieskim pryzmacie",
  "if there is a possibility to use web services or to expose them for other processes":
    "czy istnieje możliwość korzystania z serwisów internetowych lub udostępnienia ich dla innych procesów",
  "if the process interacts with web applications via browsers":
    "jeśli proces wchodzi w interakcję z aplikacjami internetowymi za pośrednictwem przeglądarek",
  "if the process interacts with Windows applications installed on the computer":
    "jeśli proces współdziała z aplikacjami Windows zainstalowanymi na komputerze",
  "if  there is any application that we access via an emulator ":
    "czy istnieje jakaś aplikacja, do której uzyskujemy dostęp przez emulator",
  "if  there is any application that we access via the cloud":
    "czy istnieje jakaś aplikacja, do której mamy dostęp przez chmurę",
  "the number of steps": "liczba kroków",
  "Are you sure you want to disregard this schedule ?":
    "Czy na pewno chcesz zignorować ten harmonogram?",
  "Schedule disregarded!": "Harmonogram zlekceważony!",
  "Are you sure you want to execute this process?":
    "Czy na pewno chcesz wykonać ten proces?",
  "Feasibility/Impact": "Wykonalność/wpływ",
  January: "Styczeń",
  March: "Marsz",
  April: "Kwiecień",
  May: "Może",
  June: "Czerwiec",
  July: "Lipiec",
  August: "Sierpień",
  September: "Wrzesień",
  October: "Październik",
  November: "Listopad",
  December: "Grudzień",
  "Weeks ago": "Tygodnie temu",

  "7 Weeks ago": "7 tygodnie temu",
  "6 Weeks ago": "6 tygodnie temu",
  "5 Weeks ago": "5 tygodnie temu",
  "4 Weeks ago": "4 tygodnie temu",
  "3 Weeks ago": "3 tygodnie temu",
  "2 Weeks ago": "2 tygodnie temu",
  "Last Week": "Zeszły tydzień",

  "hours ago": "#NB# godzin temu",
  "1 hour ago": "1 godzinę temu",
  xHoursAgo: "{{hours}} godzin temu",

  Monday: "Poniedziałek",
  Tuesday: "Wtorek",
  Wednesday: "Środa",
  Thursday: "Czwartek",
  Friday: "Piątek",
  Saturday: "Sobota",
  Sunday: "Niedziela",
  "Total executions": "Razem egzekucje",
  "Latest activities": "Najnowsze działania",
  "of the total": "z całości",
  "Creation date (Descending)": "Data utworzenia (malejąco)",
  "Creation date (Ascending)": "Data utworzenia (rosnąco)",
  "Exception Time (Descending)": "Data wyjątku (malejąco)",
  "Exception Time (Ascending)": "Data wyjątku (rosnąco)",
  "End date (Descending)": "Data zakonczenia (malejąco)",
  "End date (Ascending)": "Data zakonczenia (rosnąco)",
  "Deferral date (Descending)": "Data odroczenia (malejąco)",
  "Deferral date (Ascending)": "Data odroczenia (rosnąco)",
  "Deferral date": "Data odroczenia",
  "Creation date": "Data utworzenia",
  "Exception date": "Data wyjątku",
  "Sort By": "Sortuj według",
  "Document Name": "Nazwa dokumentu",
  "Document Status": "Status dokumentu",
  Preview: "Zapowiedź",
  "The document was sent to the orchestrator":
    "Dokument został wysłany do orkiestratora",
  "The process has started successfully": "Automatyzacja rozpoczął się pomyślnie",
  "Are you sure you want to delete this document ?":
    "Czy na pewno chcesz usunąć ten dokument?",
  "Document Deleted Successfully": "Dokument został pomyślnie usunięty",
  "Show empty fields": "Pokaż puste pola",
  "Are you sure you want to delete this Opportunity ?":
    "Czy na pewno chcesz usunąć tę możliwość?",
  "Opportunity deleted successfully": "Możliwość została pomyślnie usunięta",
  "Are you sure you want to start this process ?":
    "Czy na pewno chcesz rozpocząć ten proces?",
  START: "POCZĄTEK",
  Of: "Z",
  FOREVER: "NA ZAWSZE",
  "Are you sure you want to delete this schedule ?":
    "Czy na pewno chcesz usunąć ten harmonogram?",
  "Are you sure you want to delete this frequency ?": "Czy na pewno chcesz usunąć tę częstotliwość?",
  "Schedule Deleted Successfully": "Harmonogram został pomyślnie usunięty",
  "Failed to delete schedule": "Nie udało się usunąć harmonogramu",
  "No options": "Brak opcji",
  Clear: "Jasne",
  "save as draft": "Zapisz jako szkic",
  "Save as draft": "Zapisz jako szkic",
  "Save draf": "Zapisz wersję roboczą",
  calculate: "Oblicz",
  Calculate: "Oblicz",
  "Can a defined rule be applied to each step of this process?":
    "Czy na każdym etapie tego procesu można zastosować zdefiniowaną regułę?",
  "To what extent is the process standard across locations?":
    "W jakim stopniu proces jest standardem w różnych lokalizacjach?",
  "The more optimized & centrally managed the process is, the easier the automation tests & implementation will be.":
    "Im bardziej zoptymalizowany i centralnie zarządzany proces, tym łatwiejsze będą testy automatyzacji i wdrożenie.",
  "Are the process inputs provided in a structured manner?":
    "Czy dane wejściowe do procesu są dostarczane w sposób ustrukturyzowany?",
  "Is a full data set availbale to test this process ?":
    "Czy dostępny jest pełny zestaw danych do przetestowania tego procesu?",
  "What is the level of IT environment complexity?":
    "Jaki jest poziom złożoności środowiska IT?",
  "What level of documentation exists to support this process?":
    "Jaki poziom dokumentacji istnieje, aby wspierać ten proces?",
  "What is scale of the security challenge for authentication?":
    "Jaka jest skala wyzwania bezpieczeństwa dla uwierzytelniania?",
  "What is the Time required for a single but complete process execution?":
    "Jaki jest czas potrzebny na wykonanie pojedynczego, ale pełnego?",
  "What is the frequency which this process occures?":
    "Jaka jest częstotliwość tego procesu?",
  "What is the value that would drive a round of process executions?":
    "Jaka jest wartość, która napędzałaby rundę wykonywania?",
  "Please indicate the nature of the driver for the occurrence of the process (number of company codes, accounts, postings, etc.)":
    "Proszę wskazać charakter kierowcy dla wystąpienia procesu (liczba kodów firmy, kont, księgowań itp.)",
  "Weekly=52, Monthly=12, Daily=220, etc. For specific frequencies, please add the details of your calculations in the comment section":
    "Tygodniowo=52, Miesięcznie=12, Dziennie=220 itd. W przypadku konkretnych częstotliwości prosimy o dodanie szczegółów swoich obliczeń w sekcji komentarzy",
  "This field is populated automatically from the details from the Time Spent Section":
    "To pole jest wypełniane automatycznie na podstawie danych z sekcji Czas spędzony",
  "Select gravity of benefits and mention in the comment area what are the benefits expected, e.g. better quality, productivity gains, reduced lead time etc.":
    "Wybierz wagę korzyści i wspomnij w polu komentarza, jakie są oczekiwane korzyści, np. lepsza jakość, wzrost wydajności, skrócony czas realizacji itp.",
  "To what extent is this same process performed worldwide?":
    "W jakim stopniu ten sam proces jest realizowany na całym świecie?",
  "Are systems/tool changes planned that would impact this process? (i.e. new report development)":
    "Czy planowane są zmiany systemów/narzędzi, które wpłyną na ten proces? (tj. opracowanie nowego raportu)",
  "Select the number of steps (Clicks, navigation, reading and writing in each application window), if necessary precise in comments":
    "Wybierz liczbę kroków (Kliknięć, nawigacji, czytania i pisania w każdym oknie aplikacji), w razie potrzeby precyzyjnie w komentarzach",
  "Select the number of windows (screens) that change during the execution of the process, if necessary precise in comments":
    "Wybierz liczbę okien (ekranów), które zmieniają się w trakcie realizacji procesu, w razie potrzeby precyzuj w komentarzach",
  "Select the number of  the business rules governing the process, if necessary precise in comments":
    "Wybierz liczbę reguł biznesowych regulujących proces, w razie potrzeby sprecyzuj w komentarzach",
  "Select the number of business applications involved in the process (Number of objects to be created), if necessary precise in comments":
    "Wybierz liczbę aplikacji biznesowych zaangażowanych w proces (Liczba obiektów do utworzenia), w razie potrzeby sprecyzuj w komentarzach",
  "Select whether unstructured data (PDF, Excel, e-mail, Txt file) or any OCR processing will be processed, if necessary precise in comments":
    "Wybierz, czy przetwarzane będą dane nieustrukturyzowane (PDF, Excel, e-mail, plik Txt) lub jakiekolwiek przetwarzanie OCR, w razie potrzeby precyzyjnie w komentarzach",
  "Select whether a communication with a database to establish or any sql commands will be executed if necessary precise in comments":
    "Wybierz, czy komunikacja z bazą danych do nawiązania lub jakiekolwiek polecenia sql zostaną wykonane w razie potrzeby dokładnie w komentarzach",
  "Select if there is any Powershell script to install or any powershell code to execute in the command line, if necessary precise in comments":
    "Wybierz, czy jest jakiś skrypt Powershell do zainstalowania lub dowolny kod powershell do wykonania w wierszu poleceń, jeśli to konieczne, sprecyzuj w komentarzach",
  "Select if the resourcess will have a Windows account and communicate by email or Teams, if necessary precise in comments":
    "Wybierz, czy zasoby będą miały konto Windows i będą komunikować się przez e-mail lub Teams, w razie potrzeby sprecyzuj w komentarzach",
  "Select if there is a need to create VB scripts or run macros directly on blue prism if necessary precise in comments":
    "Zaznacz, czy istnieje potrzeba tworzenia skryptów VB lub uruchamiania makr bezpośrednio na niebieskim pryzmacie, jeśli to konieczne, precyzyjnie w komentarzach",
  "Select if there is a possibility to use web services or to expose them for other processes, if necessary precise in comments":
    "Zaznacz, czy istnieje możliwość korzystania z serwisów internetowych lub udostępnienia ich innym procesom, w razie potrzeby precyzuj w komentarzach",
  "Select if the process interacts with web applications via browsers (IE, Chrome, Firefox), if necessary precise in comments":
    "Zaznacz, czy proces współdziała z aplikacjami internetowymi za pośrednictwem przeglądarek (IE, Chrome, Firefox), w razie potrzeby precyzyjnie w komentarzach",
  "Select if the process interacts with Windows applications installed on the computer, if necessary precise in comments":
    "Wybierz, czy proces współdziała z aplikacjami Windows zainstalowanymi na komputerze, w razie potrzeby sprecyzuj w komentarzach",
  "Select if  there is any application that we access via an emulator (IBM, AS400, HUB 5250), if necessary precise in comments":
    "Zaznacz, czy istnieje jakaś aplikacja, do której mamy dostęp przez emulator (IBM, AS400, HUB 5250), w razie potrzeby doprecyzuj w komentarzach",
  "Select if  there is any application that we access via the cloud (image), if necessary precise in comments":
    "Zaznacz, czy jest jakaś aplikacja, do której mamy dostęp przez chmurę (obrazek), w razie potrzeby sprecyzuj w komentarzach",
  "Select if this process can be automated without the use of RPA technologies":
    "Wybierz, czy ten proces można zautomatyzować bez użycia technologii RPA",
  "Are you sure you want to save this case as a draft?":
    "Czy na pewno chcesz zapisać tę sprawę jako wersję roboczą?",
  "Case saved as a draft": "Sprawa zapisana jako wersja robocza",
  "Are you sure you want to save and submit this case later?":
    "Czy na pewno chcesz zapisać i przesłać tę sprawę później?",
  "Case saved but not submitted": "Sprawa zapisana, ale nie przesłana",
  "Are you sure you want to save and submit this case?":
    "Czy na pewno chcesz zapisać i przesłać tę sprawę?",
  "Case saved and submitted for approval":
    "Sprawa zapisana i przesłana do zatwierdzenia",
  "rpaQual.transition.accepted":
    "Czy na pewno chcesz zaakceptować tę sprawę jako okazję do automatyzacji?",
  "Case accepted as an opportunity": "Sprawa zaakceptowana jako okazja",
  "Are you sure you want to reject this case?":
    "Czy na pewno chcesz odrzucić tę sprawę?",
  "Case rejected": "Sprawa odrzucona",
  "This case is not yet submitted. Are you sure you want to delete it?":
    "Ta sprawa nie została jeszcze złożona. Czy na pewno chcesz go usunąć?",
  "Case deleted successfully": "Sprawa została pomyślnie usunięta",
  "This case is submitted and awaiting approval. Are you sure you want to delete it?":
    "Ta sprawa została przesłana i oczekuje na zatwierdzenie. Czy na pewno chcesz go usunąć?",
  "This case is an accepted opportunity for automation. Are you sure you want to delete it?":
    "Ten przypadek jest akceptowaną okazją do automatyzacji. Czy na pewno chcesz go usunąć?",
  "Are you sure you want to delete this case?":
    "Czy na pewno chcesz usunąć tę sprawę?",
  "Your progress will be lost, are you sure you want to exit?":
    "Twoje postępy zostaną utracone, czy na pewno chcesz wyjść?",
  "Total in man-days": "Razem w osobodniach",
  "Running costs in man-days": "Koszty bieżące w osobodniach",
  months: "miesiące",
  Months: "Miesiące",
  Weeks: "Tygodnie",
  Years: "Lata",
  days: "dni",
  weeks: "tygodnie",
  years: "lat",
  left: "lewy",
  ago: "Odkąd {{value}} {{unit}}",
  Logout: "Wyloguj",
  Profile: "Profil",
  "Choose your timezone": "Wybierz swoją strefę czasową",
  Analytics: "Raportowanie",
  "Alerts Management": "Alerty",
  "Execution Count": "Egzekucje",
  "Exception.count": "Wyjątki",
  process: "Automatyzacja",
  "point.amortissage.negative": "{{value}} {{unit}} pozostała",
  SearchProcess: "Wyszukaj proces",
  searchUser: "Wyszukaj użytkownika",
  status: "Status",
  sortBy: "Sortuj według",
  "Failed To create User": "Nie udało się utworzyć użytkownika",
  "Failed To create Scheduler": "Nie udało się utworzyć harmonogramu",
  "user.keycloak.exists": "Użytkownik już istnieje w Keycloak",
  "user.email.exists": "Użytkownik już istnieje",
  "User with same email already exists": "Użytkownik o tym samym e-mailu już istnieje",
  "user.update.fail": "Aktualizacja użytkownika nie powiodła się",
  "user.update.success": "Użytkownik pomyślnie zaktualizowany!",
  "invalid.value": "niewłaściwa wartość",
  Required: "Wymagany",
  deleteUsersActionMessage: "Czy na pewno chcesz usunąć tych użytkowników?",
  "profile.update-password.current-password-required":
    "Aktualne hasło jest wymagane",
  "profile.update-password.new-password-required": "Wymagane jest nowe hasło",
  "profile.update-password.password-required": "Wymagane jest hasło",
  "profile.update-password.password-not-matched": "Hasła nie pasują do siebie",
  "profile.update-password.current-password": "Aktualne hasło",
  "profile.update-password.new-password": "Nowe hasło",
  "profile.update-password.confirm-password": "Potwierdź nowe hasło",
  "profile.update-password.reset-password": "Zresetuj hasło",
  "profile.update-password.password-reset": "Zresetowanie hasła",
  "profile.update-password.password-reset-success":
    "Twoje hasło zostało pomyślnie zmienione",
  "profile.update-password.password-email-send":
    "E-mail został wysłany. Proszę sprawdzić swoją skrzynkę pocztową.",
  "profile.update.password.success": "Hasło zostało pomyślnie zmienione",
  "profile.update.password.wrong.current.password": "Bieżące hasło jest nieprawidłowe",
  "profile.update-password.password-reset-done": "Gotowe",
  "profile.update-password.update-password": "Zmień hasło",
  "profile.update-password.email": "Twój aktualny e-mail",
  "profile.update-password.tokenError":
    "Nieprawidłowy token, hasło jest już zaktualizowane!",
  "token.validation.noteExist": "Prośba o zmianę hasła została już wysłana",
  "Process CompletedItems": "Zakończony",
  "Completed time": "Ukończony czas",
  "traited item": "przedmioty przetworzone",
  "Items Count": "Przetworzone przedmioty",
  "process.devCost.edit.cancel":
    "Twoje zmiany zostaną utracone, czy na pewno chcesz anulować?",
  "process.devCost.edit.confirm": "Czy na pewno chcesz zapisać zmiany?",
  "process.status.pending": "W OCZEKIWANIU",
  "not submitted": "NIE PRZESŁANE",
  "Process Assessment": "Ocena możliwości",
  "title.processassessment": "OCENA PROCESU",
  "no.exception.message": "Nie wykryto wyjątków",
  "show.all.exception.button": "Pokaż wszystko",
  manageUsers: "Zarządzaj użytkownikami",
  "assessment.Accounting & Finance": "Księgowość i finanse",
  "assessment.Compliance": "Zgodność",
  "assessment.Customer Service": "Obsługa klienta",
  "assessment.Purchasing": "Nabywczy",
  "assessment.IT Operations": "operacje informatyczne",
  "assessment.Maintenance": "Konserwacja",
  "assessment.Sales": "Sprzedaż",
  "assessment.Marketing": "Marketing",
  "assessment.Research and Development": "Badania i rozwój",
  "assessment.Production": "Produkcja",
  "assessment.Human Resources Management ": "Zarządzanie zasobami ludzkimi",
  "assessment.Software Development": "Rozwój oprogramowania",
  "assessment.Other": "Inny",
  "assessment.Seconds": "Sekundy",
  "assessment.Minutes": "Minuty",
  "assessment.Hours": "Godziny",
  "assessment.Days": "Dni",
  "assessment.Months": "Miesiące",
  "assessment.Years": "Lata",
  "assessment.Weeks": "Tygodnie",
  "orchestrator.management.menu.title": "Orkiestratora",
  "resources.management.title": "Administracja ratunekem",
  "resource.management.menu.title": "Rasoby",
  "resource.management.attributeName": "Atrybut zasobu",
  "resource.management.orchestratorName": "Orkiestrator",
  "resource.management.displayStatus": "Status wyświetlacza",
  "resource.management.processesRunning": "Uruchomione automatyzacje",
  "Process Occurence": "Ukończone przedmioty",
  "Process Item": "Rzeczy",
  "orchestrator.management.add.button": "Połącz orchestratora",
  "orchestrator.management.delete.confirmMsg":
    "Czy na pewno chcesz usunąć koordynatora ? Wszystkie dane zostaną trwale utracone.",
  "orchestrator.management.deleteOrchestratorsActionMessage":
    "Czy na pewno chcesz usunąć wybranych koordynatorów?",
  "orchestrator.management.enaDes.confirmMsg":
    "Czy na pewno chcesz włączyć koordynatora ? Połączenie ze środowiskiem RPA zostanie ponownie zainicjowane",
  "orchestrator.management.enaDes.button.confirm": "Aktywuj",
  "orchestrator.management.desaDes.confirmMsg":
    "Czy na pewno chcesz wyłączyć koordynatora ? Połączenie ze środowiskiem RPA zostanie utracone.",
  "orchestrator.management.desaDes.button.confirm": "Dezaktywować",
  "orchestrator.management.status": "Status",
  "orchestrator.management.name": "Nazwa",
  "orchestrator.management.solutionProvider": "Dostawca rozwiązań",
  "orchestrator.management.isActionable": "jest wykonalny",
  "orchestrator.management.orchestratorIp": "Publiczny adres IP",
  "orchestrator.management.isAcionableToggle":
    "Czy na pewno ten koordynator nie umożliwia działań?",
  "orchestrator.management.isAcionableToggle.confirm": "Tak, jestem pewny",
  "orchestrator.management.isNotAcionableToggle":
    "Czy na pewno chcesz, aby ten koordynator mógł działać?",
  "orchestrator.management.isNotAcionableToggle.confirm":
    "Spraw, by był wykonalny",
  "orchestrator.management.editHeader": "Edytuj Orchestrator",
  "orchestrator.management.addHeader": "Dodaj orkiestratora",
  "orchestrator.management.displayHeader": "Szczegóły orkiestratora",
  "orchestrator.management.formControl.required": "Pole wymagane",
  "orchestrator.management.formControl.orchestratorType": "Typ orkiestratora",
  "orchestrator.management.formControl.orchestratorId":
    "Identyfikator orkiestratora",
  "orchestrator.management.formControl.orchestratorName": "Nazwa orkiestratora",
  "orchestrator.management.formControl.orchestratorName.information":
    "Nazwa orkiestratora",
  "orchestrator.management.formControl.description.information":
    "Wszelkie informacje, które musisz dodać tutaj",
  "orchestrator.management.formControl.orchestratorIp.information":
    "Publiczne lub prywatne adres IP z maszyny orkiestrator",
  "orchestrator.management.formControl.sessionNumber.information":
    "Liczba sesji dozwolonych w bieżącej aktywnej licencji BP",
  "orchestrator.management.formControl.databaseServer.information":
    "Nazwa hosta serwera bazy danych",
  "orchestrator.management.formControl.serverPort.information":
    "Musi to pasować do portu słuchania skonfigurowanego na serwerze SQL",
  "orchestrator.management.formControl.databaseName.information":
    "Nazwa bazy danych używanej przez BluePrism",
  "orchestrator.management.formControl.dbUsername.information":
    "Nazwa użytkownika bazy danych do użycia podczas łączenia.Użytkownik musi mieć uprawnienia DB_Datareader i DB_DATAWriter w bazie danych Blue Prism",
  "orchestrator.management.formControl.dbPassword.information":
    "Hasło nazwy użytkownika używane podczas łączenia",
  "orchestrator.management.formControl.apiClientId.information": "Identyfikator aplikacji dostarczony przez UiPath podczas tworzenia",
  "orchestrator.management.formControl.apiClientSecret.information": "Sekret aplikacji dostarczony przez UiPath podczas tworzenia",
  "orchestrator.management.formControl.organizationName.information": "Nazwa organizacji w Orchestratorze UiPath",
  "orchestrator.management.formControl.tenantName.information": "Nazwa najemcy, z którego będą pobierane dane",
  "orchestrator.management.formControl.orchestratorApiName.information": "Nazwa Orchestratora UiPath",
  "orchestrator.management.formControl.apiClientId": "ID aplikacji API",
  "orchestrator.management.formControl.apiClientSecret": "Sekret aplikacji API",
  "orchestrator.management.formControl.solutionProvider": "Dostawca rozwiązań",
  "orchestrator.management.formControl.orchestratorIp":
    "Adres IP orkiestratora",
  "orchestrator.management.formControl.yes": "TAk",
  "orchestrator.management.formControl.no": "Nie",
  "orchestrator.management.formControl.orchestratorId.actionnable":
    "Zaskarżalny",
  "orchestrator.management.formControl.apiPath": "Ścieżka API",
  "orchestrator.button.update": "Aktualizuj Orchestrator",
  "orchestrator.delete.discard": "Czy na pewno chcesz zignorować zmiany?",
  "orchestrator.management.save.confirmMsg":
    "Czy na pewno chcesz zapisać tego koordynatora?",
  "user.enaDes.confirmMsg": "Czy na pewno chcesz usunąć użytkownika?",
  "orchestrator.management.formControl.description": "Opis",
  "orchestrator.management.formControl.databaseServer": "Serwer bazy danych",
  "orchestrator.management.databaseServer": "Serwer bazy danych",
  "orchestrator.management.formControl.serverPort": "Port",
  "orchestrator.management.serverPort": "Port",
  "orchestrator.management.formControl.databaseName": "Nazwa bazy danych",
  "orchestrator.management.formControl.dbUsername": "Nazwa użytkownika",
  "orchestrator.management.formControl.dbPassword": "Hasło",
  "orchestrator.management.formControl.invalidPort":
    "Numer portu, wynosi od 1 do 65 535",
    "orchestrator.management.formControl.organizationName": "Nazwa organizacji Uipath",
    "orchestrator.management.formControl.tenantName": "Nazwa najemcy Uipath",
    "orchestrator.management.formControl.orchestratorApiName": "Nazwa orkiestratora Uipath",
  "orchestrator.management.testConnection": "Test połączenia",
  "orchestrator.management.testConnection.successMessage": "Połączyć się udało",
  "orchestrator.management.testConnection.failureMessage":
    "Połączenie nie powiodło się",
  "Are you sure you want to accept this case as an opportunity for automation ?":
    "Czy na pewno chcesz zaakceptować tę sprawę jako okazję do automatyzacji?",
  "Are you sure you want to save the changes?":
    "Czy na pewno chcesz zapisać zmiany?",
  "Schedule modified successfully": "Twoje żądanie zostało wysłane do {{orchestrator}} i harmonogram zostanie wkrótce zaktualizowany",
  "Schedule saved successfully": "Częstotliwość zapisana pomyślnie",
  "Frequency Deleted Successfully": "Częstotliwość Usunięto pomyślnie",
  "Failed to update the schedule! Please try again later":
    "Nie udało się zaktualizować harmonogramu! Spróbuj ponownie później",
  "Create schedule": "Utwórz harmonogram",
  "Edit schedule": "Zaktualizuj harmonogram",
  "Confirm frequency": "Potwierdź częstotliwość",
  "Update frequency": "Aktualizacja częstotliwości",
  "Choose Process": "Wybierz proces",
  "Choose Resources": "Wybierz ratunek",
  "Disregard changes": "Odrzucić",
  "Save changes": "Zapisz zmiany",
  apply: "STOSOWAĆ",
  reset: "RESETOWANIE",
  "3 DAYS": "3 dni",
  processes: "Automatyka",
  ACTIVE: "Aktywny",
  IDLE: "Bezczynny",
  PRIVATE: "Prywatny",
  WORKING: "Pracujący",
  OFFLINE: "Niepowiązany",
  Idle: "Bezczynny",
  Working: "Pracujący",
  Offline: "Niepowiązany",
  DISABLED: "Nieaktywny",
  DISABLE: "Nieaktywny",
  Disabled: "Wyłączone",
  Missing: "Brakujący",
  DISCONNECTED: "Odłączony",
  "Process status": "Stan procesu",
  "timeUnite.millis": " ms",
  "timeUnite.sec": " sek",
  "timeUnite.min": " min",
  "timeUnite.hrs": " Godzina",
  "timeUnite.days": " dzień",
  "timeUnite.manDay": "OD",
  "timeUnite.fte": "FTE",
  "Choose icon": "Wybierz ikonę",
  "Edit Icon": "Ikona edycji",
  export: "Eksport",
  "user.management.menu.title": "Użytkownicy",
  "User Administration": "Użytkownicy",
  "Group Administration": "Administracja zespół",
  "Tag Administration": "Administracja tagami",
  Administration: "Administracja",
  "user.management.add.button": "Utwórz użytkownik",
  "user.management.delete.confirmMsg":
    "Czy na pewno chcesz usunąć tego użytkownika?",
  "user.management.deleteUsersActionMessage":
    "Czy na pewno chcesz usunąć wybranych użytkowników?",
  "user.management.enaDes.confirmMsg":
    "Czy na pewno chcesz aktywować tego użytkownika?",
  "user.management.enaDes.button.confirm": "Aktywuj",
  "user.management.desaDes.confirmMsg":
    "Czy na pewno chcesz dezaktywować tego użytkownika?",
  "user.management.desaDes.button.confirm": "Dezaktywować",
  depuis: "Odkąd",
  "depuis la premiere execution": "pierwszej egzekucji odnalezionej",
  "kpi.parameter.be": "Uwzględnić dane od",
  "kpi.parameter.includeException":
    "Uwzględnij wyjątki biznesowe pozycji w finansowych wskaźnikach KPI",
  "kpi.parameter.executionsIncludeException":
    "Uwzględnij wyjątki dotyczące realizacji w obliczeniach finansowych KPI",
  "kpi.parameter.invalidRate": "Nieprawidłowa stawka",
  "kpiParam.update.success": "Ustawienia zostały pomyślnie zaktualizowane",
  "kpiParam.update.error": "Nie udało się zaktualizować parametrów KPI",
  "kpiParam.reset.success": "Ustawienia zostały zresetowane pomyślnie",
  "kpiParam.reset.error": "Nie udało się zresetować parametrów KPI",
  "kpi.parameter.includeSince.tooltip":
    "Wybierz, kiedy SmartRoby zacznie używać i wyświetlać dane o procesach w modułach i kluczowych wskaźnikach wydajności w całym portalu.",
  "routes.home": "Dom",
  "kpi.parameter.includeExceptFinancial.tooltip":
    "Zawiera procent czasu oszczędzonego dla każdego elementu przetworzonego z wyjątkiem biznesowym",
  "kpi.parameter.includeExceptFinancialKPI.tooltip":
    "Zawiera procent czasu oszczędzonego dla każdej sesji, która zakończyła się wyjątkiem biznesowym",
  "kpi.parameter.breakEvenCalculationPeriod.tooltip":
    "Okres czasu uwzględniany przy szacowaniu punktu zwrotnego dla tej automatyzacji",
  "routes.orchestrators": "orkiestratorzy",
  "routes.orchestrators.processAssessment": "Ocena procesu",
  "routes.orchestrators.processAssessment.rpa": "rpa",
  "routes.orchestrators.processAssessment.rpa.view": "pogląd",
  "routes.orchestrators.processAssessment.rpa.create": "Stwórz",
  "routes.orchestrators.processAssessment.rpa.edit": "edytować",
  "routes.orchestrators.smartResources": "Inteligentne zasoby",
  "routes.orchestrators.myProcesses": "Automatyka",
  "My Processes": "Automatyzacje",
  "routes.orchestrators.smartResources.documents": "dokumenty",
  "routes.orchestrators.smartResources.documents.details": "Detale",
  "routes.orchestrators.administration": "administracja",
  "routes.orchestrators.administration.users": "użytkownicy",
  "routes.orchestrators.administration.users.add": "Dodaj",
  "routes.orchestrators.administration.users.edit": "edytować",
  "routes.orchestrators.administration.users.view": "pogląd",
  "routes.orchestrators.protected": "chroniony",
  "routes.orchestrators.protected.user": "użytkownik",
  "routes.orchestrators.protected.user.profile": "profil",
  "routes.orchestrators.administration.orchestrators": "orkiestratorzy",
  "routes.orchestrators.administration.orchestrators.add": "Dodaj",
  "routes.orchestrators.administration.orchestrators.edit": "edytować",
  "routes.orchestrators.administration.orchestrators.view": "pogląd",
  "routes.orchestrators.smartocr": "inteligentny OCR",
  "routes.orchestrators.smartocr.identity_card": "dowód osobisty",
  "routes.orchestrators.smartocr.passport": "paszport",
  "routes.orchestrators.smartocr.driver_licence": "prawo jazdy",
  "routes.orchestrators.smartocr.car_registration": "Rejestracja samochodu",
  "routes.orchestrators.smartocr.green_card": "Zielona Karta",
  "routes.orchestrators.smartocr.rib": "żebro",
  "routes.orchestrators.smartocr.invoice": "faktura",
  "routes.orchestrators.smartocr.purchase_order": "zamówienie",
  "routes.orchestrators.smartocr.tax_notice": "zawiadomienie o podatkach",
  "routes.orchestrators.smartocr.receipt": "paragon fiskalny",
  "routes.orchestrators.smartocr.edf": "edf",
  "routes.orchestrators.smartocr.payroll_statemenet": "payroll_statemenet",
  "routes.orchestrators.exceptions": "Wyjątki",
  "routes.orchestrators.exceptions.processes": "Wyjątki procesowe",
  "routes.orchestrators.exceptions.items": "Wyjątki dotyczące przedmiotów",
  "routes.orchestrators.process-occurrence": "Zdarzenia procesowe",
  "routes.orchestrators.alertsManagement": "Alerty",
  "routes.orchestrators.alertsManagement.defaultAlerts": "Alerty domyślne",
  "routes.orchestrators.alertsManagement.customAlerts": "Alerty niestandardowe",
  "routes.orchestrators.alertsManagement.defaultAlerts.add": "Dodać",
  "routes.orchestrators.alertsManagement.defaultAlerts.edit": "Edytować",
  "routes.orchestrators.alertsManagement.defaultAlerts.view": "Pogląd",
  "routes.orchestrators.alertsManagement.customAlerts.add": "Dodać",
  "routes.orchestrators.alertsManagement.customAlerts.edit": "Edytować",
  "routes.orchestrators.alertsManagement.customAlerts.view": "Pogląd",
  "exceptions.item.tab.title": "Wyjątki",
  "exceptions.processes.tab.title": "Wyjątki procesowe",
  "routes.orchestrators.process-occurence": "proces-występowanie",
  "routes.orchestrators.profile": "profil",
  "routes.orchestrators.administration.fleets": "klienci",
  "routes.orchestrators.administration.fleets.add": "Dodaj",
  "routes.orchestrators.administration.fleets.edit": "edytować",
  "routes.orchestrators.administration.fleets.view": "pogląd",
  "save draft": "ZAPISZ PROJEKT",
  "user.title.editHeader": "Edytuj użytkownika",
  "fleet.management.status": "Status",
  "fleet.management.companyName": "Firma",
  "fleet.management.email": "E-mail",
  "fleet.management.clientSince": "Grupa utworzona od",
  "fleet.management.menu.title": "Zespół",
  groups: "Zespół",
  "fleet.management.add.button": "Utwórz zespół",
  "fleet.management.delete.confirmMsg":
    "Czy na pewno chcesz usunąć tego klienta?",
  "fleet.management.deleteClientsActionMessage":
    "Czy na pewno chcesz usunąć wybranych klientów?",
  "fleet.management.enaDes.confirmMsg":
    "Czy na pewno chcesz aktywować tego klienta?",
  "fleet.management.enaDes.button.confirm": "Aktywuj",
  "fleet.management.desaDes.confirmMsg":
    "Czy na pewno chcesz dezaktywować tego klienta?",
  "fleet.management.desaDes.button.confirm": "dezaktywować",
  "fleet.management.addHeader": "Dodaj klienta",
  "fleet.management.editHeader": "Edytuj klienta",
  "fleet.management.displayHeader": "Dane klienta",
  "fleet.management.formControl.required": "Pole wymagane",
  "fleet.management.formLabel.companyName": "Nazwa grupy",
  "fleet.management.formControl.email":
    "Nieprawidłowy format wiadomości e-mail",
  "fleet.management.formControl.siret.notValid": "SIRET nieważny",
  "fleet.management.formControl.siren.notValid": "SYRENA nieważna",
  "fleet.management.formLabel.email": "E-mail",
  "fleet.management.formLabel.phoneNumber": "Numer telefonu",
  "fleet.management.formLabel.vatNumber": "NIP",
  "fleet.management.formControl.vatNumber.notValid": "Wartość musi być liczbą",
  "fleet.management.formLabel.address": "Pełny adres",
  "fleet.management.formLabel.subscription": "Subskrypcja",
  "fleet.management.formLabel.clientSince": "Klient od",
  "fleet.button.update": "Aktualizacja",
  "fleet.button.save": "Dodać",
  "fleet.button.cancel": "Anulować",
  "fleet.update.confirmMsg":
    "Czy na pewno chcesz zaktualizować informacje o grupa?",
  "fleet.save.confirmMsg": "Czy na pewno chcesz dodać grupa?",
  "fleet.delete.discard": "Czy na pewno chcesz odrzucić zmiany?",
  "fleet.button.discard": "Wyrzucać",
  "fleet.formControl.updateSuccess":
    "Pomyślnie zaktualizowano informacje o kliencie",
  "fleet.formControl.updateError":
    "Błąd podczas aktualizowania informacji o kliencie",
  "fleet.formControl.saveError": "Błąd podczas dodawania nowego klienta",
  "fleet.management.formControl.invalidDate": "Zły format daty",
  "orchestrator.formControl.updateSuccess":
    "Pomyślnie zaktualizowano informacje programu Orchestrator",
  "user.management.formLabel.fleet": "Zespół",
  "processesSelector.availableProcess": "Dostępne automatyka",
  "processesSelector.assignedProcess": "Przypisane automatyka",
  "processTagSelector.assignedProcessTags": "Przypisane automatyka i tagi",
  "tagSelector.availableTags": "dostępne tagi",
  "user.formControl.saveError": "Błąd podczas dodawania nowego użytkownika",
  "user.formControl.updateError":
    "Błąd podczas aktualizowania informacji o kliencie",
  "user.formControl.updateSuccess":
    "Pomyślnie zaktualizowano informacje o użytkowniku",
  "user.formControl.saveSuccess": "Pomyślnie dodano nowego użytkownika",
  "user.permissions.title": "Uprawnienia użytkownika",
  "user.permission.save": "Ratować",
  "user.permissions.template.createBtn": "Utwórz szablon",
  "user.add.template": "Szablon",
  "user.permission.confirmSave":
    "Czy na pewno chcesz zaktualizować uprawnienia użytkowników?",
  "user.permission.confirmDelete": "Czy na pewno chcesz usunąć ten szablon?",
  "user.permission.saveAsTemplace": "Zapisz i utwórz szablon",
  "user.permission.saveAsTemplaceTitle": "Nowy szablon",
  "user.permission.templateCreate.success": "Szablon utworzony pomyślnie",
  "user.permission.templateDelete.success": "Szablon usunięte pomyślnie",
  "user.permission.templateCreate.error": "Nazwa szablonu już istnieje",
  "permission.action.applyTemplate": "Zastosuj szablon",
  "user.permissions.template.name": "Nazwa szablonu",
  "user.permissions.default": "Domyślna",
  "user.add.permissions": "Uprawnienia",
  "fleet.formControl.saveSuccess": "Pomyślnie dodano nowego klienta",
  "orchestrator.formControl.saveSuccess":
    "Pomyślnie dodano nowego koordynatora",
  "fleet.formControl.unassignProcessError":
    "Automatyzacja jest już przypisany do {{users}} użytkowników",
  "fleet.management.delete.warning":
    "Usunięcie klienta spowoduje automatyczne usunięcie wszystkich jego użytkowników i anulowanie przypisania jego procesów!",
  "user.administration.fleet": "Zespół",
  "user.administration.lastLogin": "Ostatnie logowanie",
  "tooltip.action.enable": "Włączać",
  "tooltip.action.disable": "Wyłączyć",
  "tooltip.action.permissions": "Uprawnienia",
  "user.management.formControl.required": "Pole wymagane",
  "user.management.formControl.email": "Nieprawidłowy format wiadomości e-mail",
  "fleet.management.formControl.phone":
    "Format numeru telefonu nie jest akceptowany",
  "user.management.formControl.phone": "Nieprawidłowy format telefonu",
  "fleet.formSection.fleetInfo": "Ogólne informacje",
  "fleet.formSection.clientParams": "Parametry klienta",
  "fleet.formSection.subscriptionInfo": "Informacje o subskrypcji",
  "fleet.formSection.clientParameters": "Ustawienia zespół",
  "fleet.management.formLabel.rate": "Wskaźnik",
  "fleet.management.formLabel.overConsumptionRate":
    "Wskaźnik nadmiernej konsumpcji",
  "fleet.management.formLabel.subscriptionPeriod": "Okres subskrypcji",
  "fleet.management.formLabel.paymentTerm": "Termin płatności",
  "fleet.formSection.processes": "Automatyka",
  "fleet.formSection.processesTags": "automatyka i tagi",
  "fleet.formSection.summary": "Podsumowanie aktywnej subskrypcji",
  "fleet.management.formLabel.rateUnit": "SAR/godzina",
  "fleet.management.formLabel.subscriptionPeriodUnit": "miesiące",
  "fleet.management.formLabel.siret": "Sznurówka",
  "fleet.management.formLabel.siren": "Syrena",
  "fleet.management.formLabel.manDayValue": "1 osobodnia (md)",
  "fleet.management.formLabel.fullTime": "1 pracownik pełnoetatowy (FTE)",
  "fleet.management.formLabel.bePeriod": "Przerwać nawet okres obliczeń",
  "fleet.management.formLabel.recommendedOccupancy": "Zalecane obłożenie",
  "fleet.management.be.period.1": "W zeszłym miesiącu",
  "fleet.management.be.period.2": "Ostatnie 3 miesiące",
  "fleet.management.be.period.3": "Ostatnie 6 miesięcy",
  "fleet.management.be.period.4": "Ostatni rok",
  "fleet.add.previous": "Poprzedni",
  fleet: "Klient",
  "process.filter.fleet": "Klient",
  "process.enabled": "Wystawiany",
  "process.filter.showAll": "Wyświetl ({{count}}) ukryte automatyka",
  "process.filter.hideHidden": "Ukryj ukryte automatyka",
  Unremarkable: "< 5 lata",
  "fleet.management.formControl.number": "Format numeru VAT jest niezgodny",
  "fleet.management.formControl.occupancyNumber": "Zalecane obłożenie musi być liczbą",
  "fleet.management.formControl.occupancyHigherThan": "Zalecana liczba osób nie może przekraczać 24",
  "fleet.management.formControl.occupancyLowerThan": "Zalecane obłożenie nie może być mniejsze niż 0",
  "fleet.management.hoursPerDay": "Godzin dziennie",
  "reporting.copyToClipboard": "Skopiuj do schowka",
  "reporting.process": "Automatyzacja",
  "reporting.kpi": "KPI",
  "Time period": "Kropka",
  "reporting.reportType": "Typ raportu",
  "reporting.date": "Data",
  "reporting.table": "Stół",
  "reporting.lineChart": "Wykres liniowy",
  "reporting.pieChart": "Wykres kołowy",
  "reporting.barChar": "Wykres słupkowy",
  "reporting.columnChart": "Wykres kolumnowy",
  "reporting.totalExecution": "Razem egzekucje",
  "reporting.totalException": "Całkowite wyjątki",
  "reporting.timeSaved": "Zaoszczędzony czas",
  "reporting.executionTime": "Czas realizacji",
  "reporting.itemExecutionTime": "Czas realizacji przedmiotu",
  "reporting.breakEven": "Rentowność",
  "reporting.roi": "Zwrot z inwestycji",
  "reporting.roiShort": "ROI",
  "reporting.roiPercentage": "ROI",
  "reporting.occupancyRate": "Obłożenie",
  "reporting.executedProcesses": "Wykonane automatyka",
  "reporting.save": "Eksportuj jako PNG",
  "reporting.copyClipboard": "Skopiuj do schowka",
  "reporting.exportPDF": "Eksportuj jako PDF",
  "reporting.clear": "Jasne",
  "reporting.from": "Z",
  "reporting.to": "Do",
  "reporting.exceptionCount": "Liczba wyjątków",
  "reporting.reportTitle": "między {{from}} a {{to}}",
  "reporting.and": " oraz",
  "reporting.presetDeletionSuccess": "Preset został pomyślnie usunięty",
  "reporting.presetDeletionError": "Nie udało się usunąć presetu",
  "reporting.presetCreationSuccess": "Presety zostały pomyślnie utworzone",
  "reporting.presetCreationError": "Nie udało się utworzyć presetu",
  "reporting.customKpiCreationOrUpdateSuccess": "Niestandardowy KPI został pomyślnie zapisany",
  "reporting.customKpiCreationOrUpdateError": "Niestandardowy KPI nie mógł zostać zapisany",
  "reporting.customKpiDeletionSuccess": "Niestandardowy KPI został pomyślnie usunięty",
  "reporting.customKpiDeletionError": "Niestandardowy KPI nie mógł zostać usunięty",
  for: "dla",
  "Results for": "Wyniki dla",
  "reporting.cumul": "Wynik skumulowany",
  "Exception rate": "Stawka wyjątków",
  "Process launcher": "Program uruchamiający proces",
  "Execution detail": "Szczegóły wykonania",
  "Business Exception": "Wyjątek biznesowy",
  "System Exception": "Wyjątek systemowy",
  Unspecified: "Nieokreślony",
  Internal: "Wewnętrzny",
  "Process.list.names.by.orchestrator": "Automatyka",
  "Resources.list.names.by.orchestrator": "Rasoby",
  "fleet.management.formLabel.effectiveStartDate":
    "Obowiązuje data rozpoczęcia",
  "rpaQual.transition.delete": "Usuwać",
  "rpaQual.transition.deleteMsg": "Czy na pewno chcesz usunąć tę możliwość?",
  "rpaQual.button.delete": "Czy na pewno chcesz usunąć tę kwalifikację?",
  "rpaQual.transition.reconsider": "Zrewidować",
  "rpaQual.transition.reconsiderMsg":
    "Czy na pewno chcesz ponownie rozważyć tę sprawę?",
  "rpaQual.transition.archive": "Archiwum",
  "rpaQual.transition.archiveMsg":
    "Czy na pewno chcesz zarchiwizować tę sprawę?",
  "rpaQual.process.archived.success": "Sprawa zarchiwizowana",
  "rpaQual.process.roadMap.success": "Dodano do mapy drogowej",
  "rpaQual.process.reconsider.success": "Możliwość ponownego rozważenia",
  "rpaQual.transition.addToRoadMap": "Dodaj do mapy drogowej",
  "rpaQual.transition.addToRoadMapMsg":
    "Czy na pewno chcesz dodać tę sprawę do swojej mapy drogowej?",
  "rpaQual.filter.show": "Uwzględnij zarchiwizowane",
  "rpaQual.filter.hide": "Wyklucz archiwalne",
  "rpaQual.display.nonArchived": "Nie zarchiwizowane",
  "rpaQual.display.archived": "Zarchiwizowane",
  "rpaQual.display.all": "Wszystko",
  "breadcrumbs.label.details": "Wejścia",
  "rpaQual.opportunity.list.empty": "Nie znaleziono okazji !",
  "rpaQual.case.list.empty": "Nie znaleziono przypadku!",
  "rpaQual.status.submitted": "W oczekiwaniu",
  "rpaQual.status.reconsider": "W oczekiwaniu",
  "rpaQual.status.accepted": "Przyjęty",
  "rpaQual.status.draft": "Projekt",
  "rpaQual.status.not submitted": "Nie złożono",
  "rpaQual.status.rejected": "Odrzucony",
  "rpaQual.status.roadMap": "Mapa drogowa",
  "filter.graph": "Spostrzeżenia",
  "filter.export": "Eksport",
  "help.process": "Proszę wybrać proces(y), których dotyczy analityka",
  "help.tags": "Proszę wybrać odpowiedni(e) tagi dla analizy",
  "help.kpi": "Wybierz KPI",
  "help.reportType":
    "Wyświetlana lista typu raportu jest oparta na wybranych KPI",
  "Select All": "Zaznacz wszystko",
  "Start process": "Rozpocznij proces",
  "Schedule process": "Zaplanuj proces",
  "KPI parameters": "Ustawienia",
  "Financial Parameters": "Parametry finansowe",
  "Edit process details": "Edytuj szczegóły procesu",
  "Enable/Disable process": "Pokaż/ukryj automatyka",
  "Item exceptions": "Wyjątki dotyczące przedmiotów",
  "Delete item exceptions": "Ukryj wyjątki procesu",
  "Retry item exceptions with changed data":
    "Ponów wyjątki pozycji ze zmienionymi danymi",
  "Retry item exceptions": "Powtórzenie wyjątków pozycji",
  Workflow: "Przepływ pracy",
  "Process execution exceptions": "Wyjątki wykonania",
  "Delete process execution exceptions": "Usuń wyjątki wykonania",
  "View process exceptions": "Wyświetl wyjątki procesu",
  "Delete process exceptions": "Ukryj wyjątki procesu",
  "View item exceptions": "Wyświetl wyjątki dotyczące przedmiotów",
  "Add opportunity": "Dodaj okazję",
  "Accept / Reject / Reconsider": "Zaakceptuj / Odrzuć / Rozważ ponownie",
  "Archive / Backlog": "Archiwum / Backlog",
  "Delete opportunity": "Usuń możliwość",
  "Add fleet": "Dodaj klienta",
  "Manage fleet": "Zarządzaj klientem",
  "Delete fleet": "Usuń klienta",
  "Add user": "Dodaj użytkownika",
  "Edit user": "Edytuj użytkowników",
  "Delete user": "Usuń użytkownika",
  "User Permissions": "Uprawnienia użytkownika",
  "Add Orchestrator": "Dodaj koordynatora",
  "Edit Orchestrator": "Edycja orkiestratorów",
  "Delete Orchestrators": "Usuń koordynatora",
  "process-occurence.cutsomdate": "Niestandardowa data",
  "exception.analytics.by.process": "Wyjątki według procesu",
  "exception.analytics.by.type": "Podsumowanie typów wyjątków",
  "exception.analytics.by.reason": "Najważniejsze powody wyjątków",
  "exception.analytics.by.trend": "Wyjątkowy trend",
  "Execution Time (Descending)": "Czas realizacji (malejąco)",
  "Execution Time (Ascending)": "Czas realizacji (rosnąco)",
  "no.process.message": "Nie wykryto żadnych procesów",
  "no.queue.message": "Nie wykryto żadnych kolejek",
  "no.completedItems.message": "Nie wykryto ukończonych elementów",
  "no.pendingItems.message": "Brak wykrytych przedmiotów",
  "no.execution.message": "Nie znaleziono wykonywania",
  "no.scheduler.message": "Nie znaleziono harmonogramu",
  "no.type.message": "Nie znaleziono typu",
  "no.orchestrator.message": "Brak orkiestratorów",
  "no.resources.message": "Nie znaleziono ratunek",
  "no.fleets.message": "Brak zespół",
  "no.users.message": "Brak użytkowników",
  "no.resourcess.message": "Nie ma jeszcze ratunek",
  "no.items.exception.message":
    "Nie znaleziono wyjątków dotyczących przedmiotów",
  "no.process.exception.message": "Nie znaleziono wyjątków procesu",
  "no.analytics.message": "Nie wykryto procesu",
  "no.planned.execution.message": "Nie znaleziono planowanych egzekucji",
  "no.activities.message": "Nie znaleziono działań",
  "no.logs.message": "Nie znaleziono dziennika",
  "alerts.default.tab.title": "Alerty domyślne",
  "alerts.custom.tab.title": "Alerty niestandardowe",
  "alert.status": "Status",
  "alert.alertName": "Nazwa",
  "default.alert.event": "Wydarzenie",
  "alert.operator": "Operator",
  "alert.condition": "Stan",
  "alert.type": "Rodzaj alertu",
  "report.type": "Typ raportu",
  "no.alerts.message": "Nie znaleziono alertów!",
  "alerts.default.addButton": "Utwórz alert",
  "custom.alert.KPI": "KPI",
  "custom.alert.process": "Automatyzacja",
  "custom.alert.searchCustomAlerts": "Alerty wyszukiwania",
  "alerts.custom.addButton": "Dodaj alert",
  "alert.management.delete.confirmMsg": "Czy na pewno chcesz usunąć ten alert?",
  "alerts.addHeader": "Nowy alert",
  "alerts.editHeader": "Edytuj alert",
  "alerts.displayHeader": "Szczegóły alertu",
  "alerts.management.required.fields": "Pole wymagane",
  "alerts.management.button.update": "aktualizacja",
  "alerts.management.button.add": "Dodaj",
  "alerts.management.button.cancel": "anulować",
  "alerts.management.button.save": "ratować",
  "alerts.management.button.exit": "Wyjście",
  "alerts.management.button.discard": "wyrzucać",
  "alerts.management.trigger": "Cyngiel",
  "alerts.management.triggers": "Wyzwalacze",
  "alerts.management.general": "Ogólne informacje",
  "alerts.management.checking.frequence": "Sprawdzanie częstotliwości",
  "alerts.management.advanced.settings.alert.text": "SmartRoby będzie sprawdzać co {{days}}, począwszy od {{date}} o {{time}}",
  "week day": "tydzień",
  "alerts.management.advanced.settings.btn": "Ustawienia zaawansowane",
  "alerts.management.simple.settings.btn": "Prosta konfiguracja",
  "alerts.management.advanced.settings.days.label": "W ciągu następujących dni",
  "alerts.management.advanced.settings.time.label": "W następujących przedziałach czasowych",
  "alerts.management.advanced.settings.all.days.label": "Każdego dnia",
  "alerts.management.advanced.settings.profile.time.zone": "Profil Strefa czasowa",
  "alerts.management.notifications": "Powiadomienia",
  "alerts.management.subscribers": "Abonenci",
  "alerts.management.name.placeholder": "Wpisz nazwę alertu",
  "alerts.management.name.label": "Nazwa alertu *",
  "alerts.management.condition.label": "Wartość *",
  "alerts.management.alertDescription.placeholder": "Wpisz opis alertu",
  "alerts.management.alertDescription.label": "Opis",
  "alerts.management.when.placeholder": "Kiedy *",
  "alerts.management.operator.placeholder": "Czy *",
  "alerts.management.process.placeholder": "Ze strony *",
  "alerts.management.of.placeholder": "Z *",
  "alerts.management.email.invalidEmail": "Nieprawidłowy format adresu e-mail",
  "alerts.management.email.invalidEmails":
    "Nieprawidłowy format adresów e-mail",
  "alerts.management.email.invalidPhoneNumber":
    "Nieprawidłowy format numeru telefonu",
  "alerts.management.email.invalidPhoneNumbers":
    "Nieprawidłowy format numerów telefonów",
  "alerts.management.severity": "Typ alertu *",
  "alerts.management.save.confirmMsg": "Czy na pewno chcesz zapisać ten alert?",
  "alerts.management.update.confirmMsg":
    "Czy na pewno chcesz zaktualizować informacje o alertach?",
  "alerts.management.delete.discard": "Czy na pewno chcesz odrzucić zmiany?",
  "Alert Frequency": "Częstotliwość",
  "alert.management.formControl.saveSuccess": "Pomyślnie dodano nowy alert",
  "alert.management.formControl.updateSuccess":
    "Informacje o alercie zostały pomyślnie zaktualizowane",
  "alert.management.formControl.delete":
    "Powiadomienie zostało usunięte",
  "alert.management.enable.email.notification":
    "Czy na pewno chcesz włączyć powiadomienia e-mail dla tego alertu?",
  "alert.management.disable.email.notification":
    "Czy na pewno chcesz wyłączyć powiadomienia e-mail dla tego alertu?",
  "alert.management.enable.sms.notification":
    "Czy na pewno chcesz włączyć powiadomienia SMS dla tego alertu?",
  "alert.management.disable.sms.notification":
    "Czy na pewno chcesz wyłączyć powiadomienia SMS dla tego alertu?",
  "alert.management.subscription.status.success": "Zaktualizowano status subskrypcji",
  "alert.management.subscription.status.error": "Błąd podczas aktualizacji statusu subskrypcji",
  "Execution Time": "Czas egzekucji",
  "Process execution duration": "Czas trwania realizacji",
  "Item execution duration": "Czas trwania wykonania elementu",
  "Total Executions": "Razem egzekucje",
  "Success Rate": "Wskaźnik sukcesu",
  "Items success rate": "Items success rate",
  "Success rate": "Wskaźnik sukcesu",
  "Exceptions Rate": "Stawka wyjątków",
  "Items exception rate": "Wskaźnik sukcesu przedmiotów",
  "Time Saved": "Zaoszczędzony czas",
  "Total time saved": "Całkowity zaoszczędzony czas",
  "Number of process executions": "Liczba wykonań",
  "Break-Even": "Próg rentowności",
  "Number of completed items": "Liczba wypełnionych pozycji",
  "Number of process executions with exceptions":
    "Liczba uruchomień z wyjątkami",
  "Number of process executions completed successfully":
    "Liczba uruchomień zakończonych sukcesem",
  "create.scheduler": "Utwórz harmonogram",
  "Edit Scheduler": "Edytuj harmonogram",
  "First day of month": "Pierwszy dzień miesiąca",
  "Last day of month": "Ostatni dzień miesiąca",
  "Day number": "Numer dnia",
  "alert.management.email.helper":
    "Możesz potwierdzić dodanie swojego adresu e-mail, klikając Enter lub spację.",
  "alert.management.sms.helper":
    "Możesz potwierdzić dodanie swojego adresu numer telefonu, klikając Enter lub spację.",
  "alert.management.trigger.helper":
    "W przypadku wielu wyzwalaczy wszystkie wyzwalacze muszą być prawdziwe, aby powiadomienie zostało wysłane.",
  "alerts.management.trigger.exceptionReasons.not.found": "Nie znaleziono istniejących powodów wyjątków dla wybranego procesu i typu wyjątku",
  Active: "Aktywny",
  info: "Informacja",
  warning: "Ostrzeżenie",
  error: "Błąd",
  EQUAL: "RÓWNY",
  TRUE: "PRAWDA",
  FALSE: "FAŁSZYWY",
  "GREATER THAN": "Lepszy niż",
  "LESS THAN": "Mniej niż",
  "GREATER OR EQUAL TO": "Większe lub równe",
  "LESS OR EQUAL TO": "Mniejsze lub równe",
  "alerts.management.alertName.maxLength.error":
    "To pole nie może przekraczać 55 znaków!",
  "alerts.management.alertDescription.maxLength.error":
    "To pole nie może przekraczać 65535 znaków!",
  "select.all": "Zaznacz wszystko",
  "add.fleet.form.vat.number.email.company.name.already.exist":
    "Klient o tym samym adresie e-mail, numerze VAT i nazwie firmy już istnieje",
  "add.fleet.form.vat.number.email.already.exist":
    "Klient z tym samym adresem e-mail i tym samym numerem VAT już istnieje",
  "add.fleet.form.vat.number.company.name.already.exist":
    "Klient o tym samym numerze VAT i nazwie firmy już istnieje",
  "add.fleet.form.email.company.name.already.exist":
    "Klient o tym samym adresie e-mail i nazwie firmy już istnieje",
  "add.fleet.form.email.already.exist":
    "Klient z tym samym adresem e-mail już istnieje",
  "add.fleet.form.vat.number.already.exist":
    "Klient o tym samym numerze VAT już istnieje",
  "add.fleet.form.company.name.already.exist":
    "Klient o tej samej nazwie firmy już istnieje",
  "add.fleet.form.vat.number.email.company.name.siren.already.exist":
    "Klient z tym samym adresem e-mail, NIP, nazwą firmy i SIREN już istnieje",
  "add.fleet.form.vat.number.email.siren.already.exist":
    "Klient o tym samym adresie e-mail, NIP i SIREN już istnieje",
  "add.fleet.form.vat.number.company.name.siren.already.exist":
    "Klient o tym samym numerze VAT, nazwie firmy i SIREN już istnieje",
  "add.fleet.form.vat.number.siren.already.exist":
    "Klient z tym samym numerem VAT i tą samą SYRENĄ już istnieje",
  "add.fleet.form.email.company.name.siren.already.exist":
    "Klient o tym samym adresie e-mail, nazwie firmy i SIREN już istnieje",
  "add.fleet.form.email.siren.already.exist":
    "Klient o tym samym adresie e-mail i SIREN już istnieje",
  "add.fleet.form.company.name.siren.already.exist":
    "Klient o tej samej nazwie firmy i tej samej SIREN już istnieje",
  "add.fleet.form.siren.already.exist": "Klient z tą samą SYRENĄ już istnieje",
  "resources.management.name": "Nazwa",
  "resources.management.formControl.actionsRunning": "Akcje w toku",
  "resources.management.formControl.processesRunning": "Automatyka w toku",
  "resources.management.formControl.resourcesName": "Nazwa ratunek",
  "resources.management.formControl.resourcesOriginalName": "Oryginalna nazwa zasobu",
  "resources.management.formControl.statusDescription": "Opis statusu",
  "resources.management.formControl.resourcesId": "Identyfikator ratunek",
  "resources.management.formControl.unitsAllocated": "Jednostki przydzielone",
  "resources.management.solutionProvider": "Dostawca rozwiązań",
  "resources.management.displayStatus": "Wyświetl stan",
  "resources.management.orchestratorName": "Orkiestrator",
  "resources.management.attributeName": "Typ ratunek",
  "resources.management.processesRunning": "Automatyka w toku",
  "resources.management.resourcesId": "Identyfikator ratunek",
  "resource.management.resourceName": "Nazwa zasobu",
  "Routes.orchestrators.administration.resourcess": "Rasoby",
  "resources.management.displayHeader": "Szczegóły ratunek",
  "resources.management.enable.confirmMsg":
    "Czy na pewno chcesz aktywować tego ratunek?",
  "resources.management.disable.confirmMsg":
    "Czy na pewno chcesz dezaktywować tego ratunek?",
  "resources.management.disable.button.confirm": "Dezaktywować",
  "resources.management.enable.button.confirm": "Aktywuj",
  "resources.button.update": "Aktualizacja",
  "resources.button.cancel": "Anulować",
  "resources.button.discard": "Wyrzucać",
  "resources.update.confirmMsg":
    "Czy na pewno chcesz zaktualizować informacje o ratunekch?",
  "resources.update.discard": "Czy na pewno chcesz odrzucić zmiany?",
  "resources.modify": "Edytować",
  "routes.orchestrators.administration.resourcess.view": "pogląd",
  "routes.orchestrators.administration.resourcess.edit": "edytować",
  "routes.orchestrators.administration.resourcess": "Rasoby",
  "administration.detail.entity.processes.title": "Automatyka",
  "start.process.no.resources.assigned": "Brak przypisanych ratunek",
  "no.process.planned": "Brak zaplanowanych procesów",
  "Last update": "Data ostatniej modyfikacji",
  orchestrator: "Orkiestrator",
  accepted: "Przyjęty",
  rejected: "Odrzucony",
  submitted: "wysłannik",
  pending: "W oczekiwaniu",
  draft: "PROJEKT",
  roadMap: "MAPA DROGOWA",
  Client: "Klient",
  "Execution per": "Wykonanie na",
  "restriction.form.frequency": "Częstotliwość",
  "restriction.form.occurence": "Występowanie",
  "Orchestrator Administration": "Administracja orkiestratora",
  "New opportunity created": "Szansa stworzona",
  "Opportunity accepted": "Przyjmowana okazja",
  "Opportunity rejected": "Możliwość odrzucona",
  "Opportunity added to the road map": "Możliwość dodana do mapy drogowej",
  "Opportunity archived": "Zarchiwizowana okazja",
  "Process activated": "Aktywowany proces",
  "Process disabled": "Automatyzacja wyłączony",
  "Orchestrator enabled": "Włączony orkiestrator",
  "Orchestrator disabled": "Orkiestrator wyłączony",
  "Orchestrator actionable": "Orkiestrator działający",
  "Orchestrator not actionable": "Orkiestrator nie jest możliwy do działania",
  "Schedule added": "Dodano harmonogram",
  "Time Spent": "Czas spędzony",
  "IT Review": "Złożoność",
  Comment: "Komentarz",
  Schedules: "Harmonogramy",
  "Delete Schedule": "Usuń harmonogram",
  "Filter List": "Lista filtrów",
  Validate: "Uprawomocnić",
  "kpi.header.breakEven.tooltip":
    "Punkt amortyzacji szacowany jest na podstawie danych historycznych. Odpowiada na pytanie, kiedy inwestycja w automatyka jest w pełni pozyskiwana? Wyniki są oparte na ustawieniach finansowych skonfigurowanych dla każdego procesu i filtrze czasu pulpitu nawigacyjnego.",
  "kpi.header.roi.tooltip":
    "Odpowiada na pytanie, ile z początkowej inwestycji zostało już zarobione? Wyniki są obliczane na podstawie parametrów finansowych skonfigurowanych dla każdego procesu oraz filtra okresów pulpitu nawigacyjnego.",
  "kpi.header.breakEven.tooltip.nodata":
    "Czekam na więcej przebiegów, aby oszacować punkt amortyzacji",
  "kpi.header.roi.tooltip.nodata":
    "Czekam na więcej egzekucji, aby obliczyć wskaźnik rentowności.",
  "kpi.header.success.rate.tooltip":
    "Wskaźnik sukcesu jest obliczany dla elementów. Wyniki są oparte na ustawieniach skonfigurowanych dla każdego procesu i filtrze przedziału czasu pulpitu nawigacyjnego.",
  "kpi.header.exception.rate.tooltip":
    "Współczynnik wyjątków jest obliczany dla elementów. Wyniki są oparte na ustawieniach skonfigurowanych dla każdego procesu i filtrze czasowym pulpitu nawigacyjnego.",
  "Export as a Pdf": "Eksportuj jako PDF",
  "dashboard.graph.treatedItems": "Ukończone przedmioty",
  "dashboard.graph.pendingItems": "Oczekujące przedmioty",
  "dashboard.graph.exceptionItems": "Elementy EN Wyjątek",
  "dashboard.graph.totalItems": "łączna liczba przedmiotów",
  "Submitting...": "Przedkładający...",
  "resources.management.deleteResourcesActionMessage": "Elementy wybrane zostaną zmienione na widoczne lub ukryte.",
  "NOT VALIDATED": "NIE POTWIERDZONE",
  "tooltip.action.hide": "Ukrywać",
  "tooltip.action.show": "Pokazać",
  "tooltip.action.showHiddenItems": "Pokaż ukryte",
  "exception.analytics.typeNotFound": "Typ wyjątku nie był określony",
  "orchestrator.management.formControl.sessionNumber":
      "Liczba dozwolonych sesji",
  "profile.user.general.title": "Ogólne informacje",
  "profile.user.general.security": "Bezpieczeństwo",
  "rpa.export.rpa": "RPA",
  "rpa.export.qualification": "KWALIFIKACJA",
  "rpa.creat.businessApplication": "Wymień aplikacje tutaj",
  "add.schedule.same.date.constraint": "Harmonogram z tą samą datą rozpoczęcia już istnieje",
  "reporting.date.errorFuture": "Wartość powinna być większa niż {{dateValue}}",
  "reporting.date.errorPast": "Wartość powinna być mniejsza niż {{dateValue}}",
  "user.add.admin": "Użytkownik administratora",
  "Items status by process": "Status przedmiotów według procesu",
  "Average Handling Time": "Średni czas obsługi",
  "Current Period": "Obecny okres",
  "Last Period": "Ostatni okres",
  "use intervals": "Użyj interwałów",
  "use cumulative": "Użyj skumulowanego",
  "sla.update.success": "Informacje SLA zostały pomyślnie zaktualizowane",
  "please enter at least one email to enable email notification":
    "Wprowadź co najmniej jeden adres e-mail, aby włączyć powiadamianie pocztą elektroniczną.",
  "please enter at least one phone number to activate the sms notification":
    "Prosimy o podanie co najmniej jednego numeru telefonu w celu aktywacji powiadomienia sms.",
   "sla.update.error": "Nie udało się zaktualizować umów SLAs.",
  resources: "Ratunek",
  Exception: "Wyjątek",
  Success: "Udanie się",
  "The calculation of the KPIs will be based on an interval":
    "Obliczanie KPI będzie oparte na interwale.",
  "Calculation of the KPIs for the whole time":
    "Obliczanie wskaźników KPI dla całego czasu pracy.",
  "Receive alerts from the following date":
    "Otrzymuj alerty od następującej daty.",
  "Stop receiving alerts from the following date":
    "Przestać otrzymywać powiadomienia od następującej daty.",
  "dashboard.tooltip.timesaved":
    "Ten wykres przedstawia rozkład zaoszczędzonego czasu (w godzinach) w okresie wybranym w filtrze okresów pulpitu nawigacyjnego. Wyniki są obliczane na podstawie ustawień finansowych skonfigurowanych dla każdego procesu i filtru procesu pulpitu nawigacyjnego.",
  "dashboard.tooltip.processOverview":
    "Ten wykres przedstawia rozkład czasu wykonania każdego procesu w stosunku do wszystkich procesów. Wyniki są oparte na filtrze przedziałów czasowych pulpitu nawigacyjnego.",
  "dashboard.tooltip.itemsProcess":
    "Ten wykres przedstawia podział stanu pozycji dla głównych procesów. Wyniki są oparte na filtrze przedziałów czasowych pulpitu nawigacyjnego.",
  "dashboard.tooltip.executionsProcess":
    "Ten wykres przedstawia rozkład wykonań w okresie wybranym w filtrze okresów pulpitu nawigacyjnego.",
  "dashboard.tooltip.averageTime":
    "Średni czas przetwarzania (AHT) to średni czas potrzebny na wykonanie elementu w tym procesie w okresie wybranym w filtrze okresu.",
  STOPPED: "Zatrzymany",
  COMPLETED: "Zakończony",
  DEBUGGING: "Debugowanie",
  ARCHIVED: "Zarchiwizowane",
  STOPPING: "Zatrzymanie",
  WARNING: "Ostrzeżenie",
  "status.running.with.warning": "Wykonuje się z ostrzeżeniem",
  "process.history.duration": "Czas trwania wykonania",
  "process.history.completedItems": "Ukończone przedmioty",
  "process.history.exceptionItems": "Elementy wyjątku",
  "process.history.resources": "Ratunek",
  AUTOMATIC: "Automatyczny",
  MANUAL: "podręcznik",

  "Process data mapping": "Mapowanie danych",
  "Are you sure you want to update the mapping ?":
    "Czy na pewno chcesz zaktualizować mapowanie ?",
  "no.data-mapping.message":
    "Mapowanie można wykonać dopiero po przetworzeniu pierwszego elementu procesu.",
  Data: "Dane",
  "New name": "Nowe imie",
  Display: "Wystawić",
  "display.in.items.list": "Pokaż na liście pozycji",
  "Orchestrator configuration": "Konfiguracja Orchestratora",
  "SmartRoby will add columns and create views and triggers on your orchestrator database":
    "SmartRoby doda kolumny i utworzy widoki i wyzwalacze na twojej bazie danych orchestratora.",
  "Initialize connectors to synchronize data between your orchestrator and SmartRoby":
    "Zainicjuj konektory, aby zsynchronizować dane między twoim orkiestratorem a SmartRoby.",
  "sync.Execute": "Wykonaj",
  "sync.Initialize": "Zainicjuj",
  "Database configuration": "Konfiguracja bazy danych",
  "Orchestrator/SmartRoby synchronization":
    "Synchronizacja Orchestrator/SmartRoby",
  "Orchestrator status": "Status Orchestratora",
  "sync.Refresh": "Odśwież",
  "sync.General": "Ogólne",
  "Database connection": "Połączenie z bazą danych",
  "Synchronization engine": "Silnik synchronizacji",
  "sync.Synchronization": "Synchronizacja",
  "sync.process": "Automatyka",
  "sync.execution": "Egzekucje",
  "sync.resource": "Zasoby",
  "sync.schedule": "Harmonogramy",
  "sync.work_queue_item": "Pozycje",
  "Number of items with Exceptions":
    "Liczba pozycji z wyjątkami",
  "sync.deleted_record": "Usuwanie rekordów",
  "sync.license": "Licencje",
  "sr.sync.license": "Licencje SmartRoby",
  "resources.management.hide.confirmMsg": "Czy na pewno chcesz ukryć tego ratunek?",
  "resources.management.show.confirmMsg":
    "Czy na pewno chcesz pokazać tego ratunek?",
  "filter.clear": "Opróżnij filtry",
  Reasons: "Powody",
  "Number of executions greater than the number of orchestrator sessions":
    "Liczba wykonań większa niż liczba sesji koordynatora",
  "List of conflicts for": "Lista konfliktów dla ",
  allowedSessionsExceededForAnOrchestrator:
      "Znaleziono {{parallelExecutions}} zadań równoległych, podczas gdy orkiestrator '{{OrhcestratorName}}' pozwala tylko na {{allowedSessions}} sesję(y).",
  concurrentExecutionInTheSameResourcesMessage:
    "Stwierdzono więcej niż jedną egzekucję w robocie '{{resourcesName}}' o godzinie {{executionDate}} pomiędzy {{plannerStart}} a {{plannerEnd}}. Pozostałe planowane wykonania rozpoczną się po zakończeniu pierwszego Zgodnie z tą kolejnością : ",
  "Number of pending items": "Liczba pozycji w toku",
  "kpi.parameter.allowScheduling": "Można zaplanować",
  "kpi.parameter.allowManual": "Możliwość ręcznego uruchomienia",
  "kpi.parameter.retryable": "Elementy wyjątków można ponawiać",
  "kpi.parameter.retryableWithData": "Elementy wyjątków można ponawiać z innymi danymi",
  "kpiParam.schedule.warning":
    "Ten proces posiada już harmonogramy. Jeśli przejdziesz dalej, wszystkie zostaną usunięte.",
  "Warnings Management": "zarządzanie ostrzeżeniami",
  "processStore.year": "Rok",
  "processStore.years": "Lata",
  "can.be.edited.for.retry": "można edytować w celu ponownej próby",
  retry: "ponawiać próbę",
  "retry-dialog.title.one-item": "Czy na pewno chcesz ponowić próbę usunięcia tego wyjątku?",
  "retry-dialog.title.multiple-items": "Czy na pewno chcesz ponowić próbę {{count}} wyjątków?",
  "retry-dialog.text.one-item":
    "Element zostanie powtórzony przy następnym wykonaniu procesu.",
  "retry-dialog.text.multiple-items":
    "Elementy będą ponownie próbowane przy następnym wykonaniu ich procesów.",
  "retry-dialog.pending-alert":
    "Oczekujące ponowienie próby dla tej pozycji już istnieje.",
  "retry-dialog.pending-alert-multiple": "Oczekujące ponowienia dla tych elementów już istnieją",
  "retry-dialog.message.warning.pending": "(Elementy, które mają już oczekującą próbę, nie zostaną poddane kolejnej próbie)",
  "retry-dialog.not-retryable": "Wybrane elementy należą do procesu, którego nie można powtórzyć",
  "retry-dialog.radio.same-data": "Ponów próbę z tymi samymi danymi",
  "retry-dialog.radio.changed-data": "Ponów próbę ze zmienionymi danymi",
  "retry-dialog.table.name": "Nazwa",
  "retry-dialog.table.old-value": "Stara wartość",
  "retry-dialog.table.new-value": "Nowa wartość",
  "retry-dialog.message.success.one-item":
    "Retryfikacja elementu została utworzona pomyślnie",
  "retry-dialog.message.success.multiple-items":
    "{{count}} powtórzenia pozycji są tworzone z powodzeniem",
  "retry-dialog.message.error.one-item": "Nie udało się stworzyć retry pozycji",
  "retry-dialog.message.error.multiple-items":
    "Nie udało się utworzyć elementów retries",
  "restriction.form.hour.maxValue.error":
    "Wartość musi być mniejsza lub równa 23.",
  "restriction.form.min.sec.maxValue.error":
    "Wartość musi być mniejsza lub równa 59.",
  attempt: "Próba",
  "attempt.chip.tooltip": "To jest {{attempt}} próba tej pozycji",
  "exception-workflow.status.ready": "Niezrealizowane",
  "exception-workflow.status.taken": "Przypisane ",
  "exception-workflow.status.done": "Zrealizowane",
  "exception-workflow.action.take": "Wziąć",
  "exception-workflow.action.assign": "Przypisz",
  "exception-workflow.action.release": "Zwolnienie",
  "exception-workflow.action.reopen": "wznawiać",
  "exception-workflow.action.markAsDone": "Oznacz jako zrealizowane",
  "exception-workflow.log.take":
    "Podjęty przez <b>{{user.firstname}} {{user.lastname}}</b>",
  "exception-workflow.log.assign":
    "Przydzielony do <b>{{targetUser.firstname}} {{targetUser.lastname}}</b> przez <b>{{user.firstname}} {{user.lastname}}</b>",
  "exception-workflow.log.markAsDone":
    "Zakończony przez <b>{{user.firstname}} {{user.lastname}}</b>",
  "exception-workflow.log.release":
    "Wydane przez <b>{{user.firstname}} {{user.lastname}}</b>",
  "exception-workflow.log.reopen":
    "ponownie otwarty przez <b>{{user.firstname}} {{user.lastname}}</b>",
  "exception-workflow.snackbar.success": "Akcja została wykonana z powodzeniem",
  "exception-workflow.snackbar.error:": "Nie udało się wykonać akcji",
  "exception-workflow.confirm.take": "Czy na pewno chcesz zrobić ten wyjątek?",
  "exception-workflow.confirm.assign":
    "Czy na pewno chcesz przypisać ten wyjątek do {{selectedUser.firstName}} {{selectedUser.lastName}}?",
  "exception-workflow.confirm.markAsDone":
    "Czy na pewno chcesz oznaczyć ten wyjątek jako zrobiony?",
  "exception-workflow.confirm.release":
    "Czy na pewno chcesz zwolnić ten wyjątek?",
  "exception-workflow.confirm.reopen":
    "Czy na pewno chcesz wznawiać ten wyjątek?",
  "exception-workflow.open": "Przepływ pracy",
  "exception-workflow.open-bulk": "Przepływ pracy",
  "exception-workflow.no-bulk-action-possible":
    "Dla wybranej kombinacji wyjątków nie jest możliwe wykonanie żadnej czynności",
  "exception-workflow.selected-message": "Wybierane są {{count}} wyjątki",
  "exception-workflow.confirm.bulk-take":
    "Czy na pewno chcesz wziąć {{count}} wyjątki?",
  "exception-workflow.confirm.bulk-assign":
    "Czy na pewno chcesz przypisać {{count}} wyjątki do {{selectedUser.firstName}} {{selectedUser.lastName}}?",
  "exception-workflow.confirm.bulk-markAsDone":
    "Czy na pewno chcesz oznaczyć {{count}} wyjątki jako wykonane?",
  "exception-workflow.confirm.bulk-release":
    "Czy na pewno chcesz wypuścić {{count}} wyjątki?",
  "exception-workflow.confirm.bulk-reopen":
    "Czy na pewno chcesz wznawiać {{count}} wyjątki?",
  "exception-workflow.choose-user": "Wybrać użytkownika",
  "exception-workflow.snackbar.success.take": "Wyjątek został pomyślnie wzięty",
  "exception-workflow.snackbar.success.assign":
    "Wyjątek został pomyślnie przypisany",
  "exception-workflow.snackbar.success.assign.notify":
    "Cesjonariusz został powiadomiony",
  "exception-workflow.snackbar.success.release":
    "Wyjątek został pomyślnie zwolniony",
  "exception-workflow.snackbar.success.markAsDone":
    "Wyjątek został pomyślnie zakończony",
  "exception-workflow.snackbar.success.reopen":
    "Wyjątek został ponownie otwarty pomyślnie",
  "exception-workflow.snackbar.success.bulkTake":
    "Wyjątki zostały podjęte pomyślnie",
  "exception-workflow.snackbar.success.bulkAssign":
    "Wyjątki zostały pomyślnie przypisane",
  "exception-workflow.snackbar.success.bulkRelease":
    "Wyjątki zostały zwolnione pomyślnie",
  "exception-workflow.snackbar.success.bulkMarkAsDone":
    "Wyjątki zakończyły się sukcesem",
  "exception-workflow.snackbar.success.bulkReopen":
    "Wyjątki zostały ponownie otwarte pomyślnie",
  Details: "Szczegóły",
  "processe.schedules.onDay.first": " - w {{startDay}}. dzień",
  "processe.schedules.onDay.second": " - w {{startDay}}. dniu",
  "processe.schedules.onDay.third": " - w {{startDay}}. dniu",
  "processe.schedules.onDay": " - w {{startDay}}. dniu",
  // error 404
  "Go to the home page":
    "Przejdź do strony głównej",
  "Sorry the page you are looking for could not be found":
    "Przepraszamy, nie udało się znaleźć strony, której szukasz",
  "orchestrator.license.originalId": "Oryginalny ID licencji",
  "orchestrator.license.starts": "Rozpocząć",
  "orchestrator.license.expires": "Wygasać",
  "orchestrator.license.installedOn": "Zainstalowane",
  "orchestrator.license.maxConcurrentSessions": "Liczba sesji",
  "orchestrator.license.status": "Status",
  "orchestrator.license.status.ACTIVE": "Aktywna",
  "orchestrator.license.status.EXPIRED": "Wygasła",
  "orchestrator.license.status.NOT_YET_ACTIVE": "Jeszcze nieaktywna",
  "A license is one month away from expiry": "Licencja ma miesiąc do wygaśnięcia",
  "A license is two months away from expiry": "Licencja wygasa za dwa miesiące",
  "tooltip.action.license": "Licencje",
  "Orchestrator licenses": "Licencje Orchestratora",
  "process.laststage": "OSTATNIA FAZA: {{lastStage}}",
  "analytics.preset.button.label": "presety",
  "analytics.preset.load": "Załaduj istniejące ustawienie wstępne",
  "analytics.preset.create": "Załaduj istniejące ustawienie wstępne",
  "analytics.preset.noPresets": "Brak zapisanych ustawień wstępnych",
  "analytics.preset.dialog.label": "Nowe ustawienie wstępne",
  "analytics.preset.dialog.field.label": "Zaprogramowana nazwa",
  "analytics.preset.dialog.field.submit": "Utwórz ustawienie wstępne",
  "analytics.preset.dialog.delete.label": "Usuń preset",
  "analytics.preset.dialog.delete.text": "Czy na pewno chcesz usunąć ten preset ?",
  "analytics.preset.dialog.delete.submit": "Usuń preset",
  "resources.tooltip.hide": "Czy na pewno chcesz pokazać {{count}} ratunek(ów)",
  "resources.tooltip.unhide": "Czy na pewno chcesz ukryć {{count}} ratunek(ów)",
  "export.confirmation.radio.grouped": "Eksportuj ze zgrupowanymi szczegółami pozycji.",
  "export.confirmation.radio.columns": "Eksportuj ze szczegółami pozycji w kolumnach.",
  "export.confiramtion.warnning": "Aby włączyć tę opcję, musisz wybrać proces.",
  FINISHED: "SKOŃCZONE",
  EXPORTING: "EKSPORT",
  COLLECTING: "ZBIERANIE",
  "exporting.title": "Eksportowanie plików",
  "exporting.no.data": "Brak plików do pobrania",
  "exporting.btn.download.all": "Ściągnij wszystko",
  "exporting.btn.download": "Ściągnij",
  "exporting.btn.delete.all": "Usuń wszystko",
  Executions: "Egzekucje",
  "routes.orchestrators.executions": "Egzekucje",
  "no.executions.message": "Nie znaleziono egzekucji",
  Trigger: "Spust",
  MANUALLY: "Ręcznie",
  SCHEDULED: "Zaplanowany",
  KEEP: "Utrzymać się w kolejce",
  DELETE: "Usuń z kolejki",
  Dates: "Daktyle",
  "Launching date": "Data uruchomienia",
  "Launching date (Descending)": "Data rozpoczęcia (malejąco)",
  "Launching date (Ascending)": "Data rozpoczęcia (rosnąco)",
  "SR Queues": "Kolejki SR",
  "queue.management.status": "Status",
  "queue.management.name": "Nazwa",
  "queue.management.choose.resources":
      "Niektóre harmonogramy nie są przypisane do żadnego ratunek, podczas migracji do oryginalnego programu Orchestrator przypisz je do:",
  "queue.management.creation-date": "Data utworzenia",
  "queue.management.active-since": "Aktywny od",
  "queue.management.nb-licences": "Liczba licencji",
  "queue.management.orchestrator": "Orkiestrator",
  "queue.management.search": "Szukaj",
  "queue.management.add": "Nowa kolejka",
  "queue.management.delete.confirm-msg": "Czy na pewno chcesz usunąć wybraną kolejkę?",
  "queue.management.bulk-delete.confirm-msg": "Czy na pewno chcesz usunąć wybrane kolejki?",
  "queue.management.enable.confirm-msg": "Czy na pewno chcesz aktywować tę kolejkę?",
  "queue.management.enable.confirm": "Aktywuj kolejkę",
  "queue.management.disable.confirm-msg": "Czy na pewno chcesz wyłączyć tę kolejkę?",
  "queue.management.disable.confirm": "Wyłącz kolejkę",
  "queue.management.section.info": "Ogólne informacje",
  "queue.management.section.processes": "Automatyka",
  "queue.management.section.resourcess": "Rasoby",
  "queue.management.form.save.success": "Kolejka SR została pomyślnie utworzona",
  "queue.management.form.update.success": "Kolejka SR została pomyślnie zaktualizowana",
  "queue.management.form.save.error": "Nie udało się utworzyć kolejki SR",
  "queue.management.form.update.error": "Nie udało się zaktualizować kolejki SR",
  "queue.management.form.validation.required": "To pole jest wymagane",
  "queue.management.form.validation.negative-nb-licences": "Liczba licencji nie może być ujemna",
  "queue.management.update": "Zaktualizuj kolejkę",
  "queue.management.save.confirm-msg": "Czy na pewno chcesz utworzyć tę kolejkę SR?",
  "queue.management.update.confirm-msg": "Czy na pewno chcesz zaktualizować tę kolejkę SR?",
  "queue.management.reset.confirm-msg": "Czy na pewno chcesz usunąć wszystkie pominięte wykonania tej kolejki SR?",
  "restriction.form.missedExecutionsPolicy": "Polityka nieudanych egzekucji",
  "restriction.userGuide.missedExecutionsPolicy": "Określa, czy pominięte wykonanie powinno zostać zachowane, czy usunięte z kolejki SR.",
  "queue.management.assign.process.part1": "Zamierzasz delegować wykonanie {{this_process}} do kolejek SR. Aby zagwarantować oczekiwaną wydajność, prosimy o powstrzymanie się od tworzenia harmonogramów i uruchamiania realizacji poza SmartRoby.",
  "queue.management.assign.process.part2": "Znaleziono harmonogram dla oryginalnego programu Orchestrator {{this_process}} {{at_its}}, czy chcesz go przenieść, aby używać go z kolejkami SR?",
  "queue.management.assign.process.part2_plural": "Znaleziono harmonogramy {{nbSchedules}} dla {{this_process}} {{at_its}} oryginalnego programu Orchestrator. Czy chcesz je przenieść, aby używały ich z kolejkami SR?",
  "queue.management.unassign.process.part1": "Zamierzasz anulować delegowanie wykonań {{this_process}} z kolejek SR. Idąc dalej, wszystkie wykonania i harmonogramy mogą pochodzić od SmartRoby lub od oryginalnego koordynatora.",
  "queue.management.unassign.process.part2": "Znaleziono harmonogram kolejek SR. Czy chcesz go przenieść do oryginalnego programu Orchestrator?",
  "queue.management.unassign.process.part2_plural": "Znaleziono {{nbSchedules}} harmonogramy kolejek SR, czy chcesz je przenieść do oryginalnego programu Orchestrator?",
  thisProcess: "ten proces",
  thisProcess_plural: "tamten proces",
  atIts: "na jego",
  atIts_plural: "u nich",
  some: "niektóre",
  "resources-selector.available-resourcess": "Dostępne zasoby",
  "resources-selector.assigned-resourcess": "Przypisane zasoby",
  "queue.management.form.validation.nb-licences-bigger-than-remaining": "Pozostało {{count}} licencji w wybranym programie Orchestrator",
  "queue.management.no-data": "Nie ma jeszcze kolejek",
  "queue.management.open-queue": "Otwórz w kolejce SR",
  "queue.management.reset-queue": "Zresetuj kolejkę SR",
  "execution.manager.title": "Kolejka SR",
  "queue reseted successfully": "Kolejka SR została pomyślnie zresetowana.",
  "error while reseting queue": "Nie udało się zresetować kolejki SR.",
  "Add SR Queue": "Dodaj kolejkę SR",
  "Edit SR Queue": "Edytuj kolejkę SR",
  "Delete SR Queue": "Usuń kolejkę SR",
  "process.assigned.srqueue.tooltip": "Ten proces jest przypisany do kolejki SR.",
  "process.not.assigned.srqueue.tooltip": "Ten proces nie jest przypisany do żadnej kolejki SR",
  "number of runing executions singular": "Egzekucja już trwa",
  "number of runing executions plural": "Niektóre egzekucje już trwają",
  "not enough licenses":
    ", nie masz dostępnych licencji. Skontaktuj się z administratorem RPA.",
  "check.connectivity":
    "Sprawdź łączność z serwerem aplikacji {{serverName}}. Wygląda na to, że połączenie zostało utracone.",
  "deleted by sr policy": "Harmonogram jest usuwany przez zasady kolejek SR",
  "execution is deleted": "Ręcznie usunięto wykonanie SR w kolejce",
  "execution is missed": "Brakuje wykonania SR w kolejce",
  "missed raison": "Nieodebrane z powodu",
  UNAVAILABLE_ROBOTS: "Niedostępne zasoby",
  NOT_ENOUGH_LICENCES: "Za mało licencji",
  BP_UNREACHABLE: "BP nieosiągalne",
  BP_UNRESPONSIVE: "BP nie odpowiada",
  "resources.formControl.succesUpdate": "Pomyślnie zaktualizowano informacje o ratunekch",
  "cant perform multi queue migration": "Dla wybranych kolejek znaleziono niektóre harmonogramy kolejek SR. Nie można ich migrować do oryginalnego programu Orchestrator.",
  "something went wrong": "Podczas przetwarzania zapytania coś poszło nie tak, spróbuj ponownie.",
  Continue: "Dalej",
  "Something.Wrong": "coś źle",
  "export.successful.notification": "Twój plik został dodany do sekcji plików eksportu i jest teraz gotowy do przeglądania i pobierania. Dodanie powiodło się.",
  revoke: "unieważnić",
  "division.formSection.divisionInfo": "Informacje o dziale",
  "division.formSection.divisionProcess": "Automatyka działu",
  "division.formSection.divisionUser": "Użytkownicy działu",
  "division.formSection.divisionAdmin": "Administratorzy działu",
  "division.name": "Nazwa działu",
  "division.description": "Opis",
  "division.fleet": "Nazwa zespół",
  "division.cancel": "Anuluj",
  "division.previous": "Wstecz",
  "division.save": "Zapisz",
  "division.next": "Dalej",
  "divisions.module.add.button": "Nowy dział",
  "usersSelector.availableUsers": "Dostępni użytkownicy",
  "usersSelector.assignedUsers": "Przypisani użytkownicy",
  "usersSelector.availableAdmins": "Dostępni administratorzy",
  "usersSelector.assignedAdmins": "Przypisani administratorzy",
  "divisions.module.title": "Administracja działami",
  "divisions.status": "Status",
  "divisions.division": "Dział",
  "divisions.fleet": "Zespół",
  "divisions.creation.date": "Data utworzenia",
  "division.form.updateSuccess": "Dział został pomyślnie zaktualizowany",
  "division.form.addSuccess": "Dział został pomyślnie dodany",
  "divisions.module.delete.confirmMsg": "Czy na pewno chcesz usunąć ten dział?",
  "divisions.module.deleteClientsActionMessage": "Czy na pewno chcesz usunąć wszystkie te działy?",
  "divisions.module.enaDes.confirmMsg": "Czy na pewno chcesz aktywować ten dział?",
  "divisions.module.enaDes.button.confirm": "Aktywuj",
  "divisions.module.desaDes.button.confirm": "Dezaktywuj",
  "divisions.module.desaDes.confirmMsg": "Czy na pewno chcesz dezaktywować ten dział?",
  "divisions.module.searchClient": "Szukaj działu",
  "division deleted successfully": "Dział został pomyślnie usunięty",
  "no.division.message": "nie znaleziono podziału",
  "fleet.formSection.divisions": "Działy",
  "divisionsSelector.availableDivision": "Dostępne działy",
  "divisionsSelector.assignedDivision": "Przypisane działy",
  "custom.kpi.item": "Przedmioty",
  "custom.kpi.process": "Automatyzacja",
  "custom.kpi.processExecution": "Wykonania",
  "custom.kpi.pending": "Oczekujący",
  "custom.kpi.completed": "Zakończony pomyślnie",
  "custom.kpi.completedWithException": "Zakończony z wyjątkiem",
  "custom.kpi.completedWithBusinessException": "Zakończony z wyjątkiem biznesowym",
  "custom.kpi.completedWithIntSysException": "Zakończony z wyjątkiem systemowym/wewnętrznym",
  "custom.kpi.executionTimeHours": "Czas wykonania (godziny)",
  "custom.kpi.timeSavedHours": "Oszczędzony czas (godziny)",
  "reporting.createKpi": "Utwórz nowy KPI",
  "custom.kpi.dialog.create.label": "Nowy KPI",
  "custom.kpi.dialog.field.name": "Nazwa KPI",
  "custom.kpi.dialog.field.element": "Dla każdego",
  "custom.kpi.dialog.field.detail": "Szczegół",
  "custom.kpi.dialog.field.allocation": "alokować",
  "custom.kpi.dialog.field.unitofmesure": "Jednostka miary",
  "custom.kpi.formControl.required": "Wymagane pole",
  "custom.kpi.formControl.pattern": "Proszę podać liczbę",
  "custom.kpi.dialog.create.field.submit": "UTWÓRZ KPI",
  "custom.kpi.dialog.delete.label": "Usuń KPI",
  "custom.kpi.dialog.delete.text": "Czy na pewno chcesz usunąć to KPI?",
  "custom.kpi.dialog.delete.submit": "Usuń KPI",
  "custom.kpi.dialog.create.label.edit": "Edytuj KPI",
  "custom.kpi.dialog.create.field.edit": "EDYTUJ KPI",
  "instance.owner": "Właściciel instancji",
  "instance.owner.confirmMsg": "Czy na pewno chcesz przeprowadzić przypisanie nowego właściciela instancji?/nTa akcja spowoduje utratę przez Twoją flotę statusu właściciela instancji.",
  "instance.owner.can.not.be.disabled": "Nie można wyłączyć właściciela instancji",
  "fleets.fleet": "Zespół",
  "no.fleet.message": "Brak jeszcze flot",
  "orchestrator.management.formControl.isPrivate": "Prywatny orchestrator",
  "orchestrator.management.formControl.automatic.tags.creation": "Automatyczne tworzenie tagów",
  "orchestrator.management.formControl.automatic.tags.creation.information": "Włączenie tej opcji spowoduje automatyczne generowanie tagów w Orchestrator dla każdej grupy procesów w Blue Prism. Wszystkie automatyzacje w grupie zostaną przypisane do odpowiedniego tagu",
  private: "Prywatny",
  "fleet.with.no.users": "Ta zespół nie ma żadnych użytkowników, nie można jej ustawić jako właściciela instancji",
  "fleet.with.no.admins": "Ta zespół nie ma żadnych administratorów, nie można jej uczynić właścicielem instancji",
  "user.fleet.disabled.message": "Zespół tego użytkownika jest wyłączona, nie można go włączyć.",
  "instance.owner.only.active.admin.can.not.be.disabled": "Nie można wyłączyć jedynego aktywnego administratora właściciela instancji",
  "search.available.processes": "przeszukaj dostępne automatyka",
  "search.assigned.processes": "przeszukaj przypisane automatyka",
  "search.available.tags": "przeszukaj dostępne tagi",
  "search.available.users": "przeszukaj dostępnych użytkowników",
  "search.assigned.users": "przeszukaj przypisanych użytkowników",
  "search.available.divisions": "przeszukaj dostępne działy",
  "search.assigned.divisions": "przeszukaj przypisane działy",
  "search.available.resourcess": "przeszukaj dostępne zasoby",
  "search.assigned.resourcess": "przeszukaj przypisane zasoby",
  "drag.and.drop.here": "Przeciągnij i upuść tutaj",
  "no.tags.message": "Jeszcze brak tagów",
  "tooltip.action.archive": "archiwum",
  "archive.confirm.msg": "Wybrane tagi zostaną zarchiwizowane",
  "tag.management.btn.confirm": "Zarchiwizuj",
  "tag.archived.success": "Tag(s) zarchiwizowany pomyślnie",
  "tag.management.deleteTagActionMessage": "Usuń wybrane tagi",
  "tag.management.archived": "Zarchiwizowany",
  Tags: "Tagi",
  "tag.management.add.button": "Utwórz etykieta",
  "tag.status": "Status",
  "tag.icon": "Ikona",
  "tag.name": "Nazwa tagu",
  "tag.description": "Opis tagu",
  "tag.management.name": "Nazwa",
  "tag.management.formControl.desc": "Opis",
  "wrong.file.type": "Nieprawidłowy typ pliku",
  "invalid license file": "Nieprawidłowy plik licencji",
  "invalid signature": "Nieprawidłowy podpis",
  "empty file": "Pusty plik",
  "wrong file extension": "Nieprawidłowe rozszerzenie pliku",
  "missing file": "Brakujący plik",
  "the submitted license file is invalid": "Przesłany plik licencji jest nieprawidłowy",
  "the signature of the license is invalid": "Podpis licencji jest nieprawidłowy",
  "the submitted file is empty": "Przesłany plik jest pusty",
  "we only accept .lic files": "Akceptujemy tylko pliki .lic",
  "please upload a license file": "Proszę przesłać plik licencji",
  activation: "Aktywacja",
  license: "Licencja",
  "import your license here": "Importuj swoją licencję tutaj...",
  activate: "Aktywuj",
  "licence.id": "Licencji ID",
  "licence.licensee": "Licencjobiorca",
  "licence.start.at": "Zaczyna się o",
  "licence.end.at": "Kończy się o",
  "licence.validation.valid": "Twoja licencja jest ważna",
  "licence.validation.expire": "Proszę odnowić swoją licencję, ponieważ wygasła.",
  "licence.goback.btn": "Wróć DO DOMU",
  "productivity.hrs": "Produktywność",
  Occupancy: "Zajętość (%)",
  "robots.occupancy": "Zajętość",
  "dashboard.monitoring.mode": "Tryb monitorowania",
  "HRS Saved": "Zapisane HRS",
  "Resource Productivity": "Produktywność zasobów",
  "Process Activity": "Aktywność procesu",
  "Working Time (HRS)": "Czas pracy (HRS)",
  "Processed Items": "Przetworzone elementy",
  "HRS Runtime": "Czas pracy HRS",
  mins: "mins",
  "recently.used.processes": "Najczęściej używane automatyka",
  "process.total.cost": "Całkowity koszt procesu",
  "tag.management.form.save.success": "Tag zapisany pomyślnie",
  "tag.management.form.update.success": "Tag zaktualizowany pomyślnie",
  "tag.management.form.save.error": "Nie udało się utworzyć tagu",
  "tag.management.form.update.error": "Nie udało się zaktualizować tagu",
  "scheduler.is.required": "Harmonogram jest wymagany",
  "tooltip.action.close": "Blisko",
  "filter by": "Filtruj według",
  showing: "Pokaż",
  to: "do",
  from: "z",
  entries: "wpisów",
  "Data mapping": "Mapowanie danych",
  Temps: "Czas",
  "tag.deleted.success": "Automatyzacja {{processName}} jest ukryty, aby aktywować ten alert, odkryj go.",
  "process.start.restriction": "Proces, który uruchamiasz, nie powinien działać dzisiaj w oparciu o umowy SLA, czy na pewno chcesz kontynuować?",
  "process.start.require.input": "Aby rozpocząć ten proces, wymagane jest wprowadzenie danych wejściowych",
  "process.inputs.button.reset": "Resetowanie",
  "process.inputs.button.save": "Dodać",
  "process.inputs.save.blank.values.title": "Wykryto niektóre parametry z pustymi wartościami wejściowymi.",
  "process.inputs.save.blank.values.message": "Czy na pewno chcesz rozpocząć te sesje z podanymi wartościami ?",
  Now: "Teraz",
  "server Type": "Typ serwera",
  "Automation Impact": "Wpływ automatyzacji",
  ERRORS_NO_DATA_AVAILABLE: "Brak dostępnych danych",
  "queue.management.resources.has.pendingExecutions": "Masz {{count}} oczekujących wykonanych operacji przy użyciu tego ratunek, proszę je usunąć, jeśli chcesz kontynuować tę operację.",
  "queue.management.resources.has.scheduled.pendingExecutions": "Zaplanowano {{count}} wykonanych operacji, proszę sprawdzić zaplanowane zadania przypisane do tego ratunek.",
  "occupancy.waiting.more": "Czekając na kolejne wykonanie, aby obliczyć zajętość ratunek",
  "select.process": "Wybierz proces",
  "changes.saved.successfully": "Zmiany zapisane pomyślnie",
  "changes.saving.failed": "Nie udało się zapisać zmian",
  "process.owner": "Właściciel procesu",
  "end date cannot be earlier than the start date": "Data zakończenia nie może być wcześniejsza niż data rozpoczęcia",
  "end date cannot be in the future": "Data zakończenia nie może być w przyszłości",
  "start date cannot be newer than the end date": "Data rozpoczęcia nie może być nowsza niż data zakończenia",
  "Start date cannot be in the future": "Data rozpoczęcia nie może być w przyszłości",
  "no.period.message": "Nie znaleziono okresu",
  "no.status.message": "Nie znaleziono statusu",
  "pending.executions.warning": "Ostrzeżenie: Ten proces ma oczekujące wykonania. Ich usunięcie teraz usunie wszystkie.",
  "calendar.formControl.saveRiskSuccess": "Pomyślnie dodany margines ryzyka",
  "export.confirm.msg.delete.all": "Czy na pewno chcesz usunąć wszystkie pliki ?",
  "export.confirm.msg.delete.selected": "Czy na pewno chcesz usunąć wybrane pliki ?",
  "export.confirm.msg.delete.one": "Czy na pewno chcesz usunąć ten plik ?",
  on: "Na",
  language: "Język",
  "queue.management.new": "UUtwórz kolejkę SR",
  "Choose.the.checking.frequency": "Dodaj częstotliwość sprawdzania",
  "resources.show.hidden": "Pokaż ukryte zasoby",
  "resources.hide.hidden": "Ukryj ukryte zasoby",
  "exceptions.show.hidden": "Pokaż ukryte wyjątki",
  "exceptions.hide.hidden": "Ukryj ukryte wyjątki",
  "user.logo.allowed.extensions": "Akceptujemy tylko rozszerzenia jpg, jpeg, png i bmp",
  "allowed.extensions": "Akceptujemy tylko rozszerzenia {{extensions}}",
  "max.size.allowed": "Maksymalny dozwolony rozmiar pliku to {{size}} MB",
  "items.pending.locked": "Obecnie trwają prace nad tym elementem",
  "items.pending.deferred": "Ten element został odroczony do {{date}}",
  "export.limit": "Jest to możliwe dla eksporterów, którzy zarejestrowali 100 000 premier, mogą uzyskać wyniki i uzyskać szczegółowe informacje, avez la possibilité de preciser vos critères de recherche.",
  "queue.create.update.restriction": "Każda kolejka musi mieć co najmniej 1 licencję, 1 przypisany proces i 1 przypisanego ratunek.",
  "custom.kpi.dialog.field.type": "Typ KPI",
  FINANCIAL_KPI: "KPI Finansowe",
  PRODUCTIVITY_KPI: "KPI wydajności",
  EFFICIENCY_KPI: "KPI Efektywności",
  "reporting.financialImpact": "wpływ finansowy",
  "reporting.resourcesProductivity": "Produktywność ratunek",
  "reporting.avgHandlingTime": "Średni czas obsługi",
  "max.datamap.reached": "Możesz wybrać tylko 3 pola do wyświetlenia na liście elementów",
  "retry.mark.done": "Ponów próbę i oznacz jako zrealizowane",
  "fleet.management.formLabel.currency": "Waluta",
  "tooltip.visit.link": "Odwiedź link",
  "tooltip.edit.link": "Zmodyfikuj link",
  "tooltip.delete.link": "Usuń link",
  "no.link.message": "Nie znaleziono linku",
  "confirm.delete.link": "Czy na pewno chcesz usunąć ten link?",
  "add.link": "Wstaw nowy link",
  "create.link": "Utwórz link",
  "confirm.add.link": "Czy na pewno chcesz utworzyć ten link?",
  "insert.link": "Wstaw",
  "link.validate.url": "Wprowadź prawidłowy adres URL",
  "link.name.max.length": "Nazwa nie może przekraczać {{max}} znaków. Proszę skrócić nazwę.",
  "link.url.max.length": "Adres URL nie może przekraczać {{max}} znaków. Proszę skrócić adres URL.",
  "link.description.max.length": "Opis nie może przekraczać {{max}} znaków. Proszę skrócić opis.",
  "link.hypertextUrl": "Łącze hipertekstowe do dokumentu zewnętrznego",
  "link.name": "Nazwa",
  "link.url": "Link",
  "link.description": "Opis",
  "link.delete.success": "Link usunięty pomyślnie",
  "link.add.success": "Link utworzony pomyślnie",
  "link.update.success": "Link zmodyfikowany pomyślnie",
  "link.add.failure": "Tworzenie linku nie powiodło się! Spróbuj ponownie później",
  "link.update.failure": "Aktualizacja linku nie powiodła się! Spróbuj ponownie później",
  "link.tab": "dokumentacja zewnętrzna",
  "View external documentation": "Wyświetl dokumentację zewnętrzną",
  "Manage external documentation": "Zarządzaj dokumentacją zewnętrzną",
  "Description (ascending)": "Opis (rosnąco)",
  "Description (descending)": "Opis (malejąco)",
  "Name (ascending)": "Nazwa (rosnąco)",
  "Name (descending)": "Nazwa (malejąco)",
  "disconnection.period": "Przez więcej niż (minuty)",
  "help.exception.type": "Proszę wybrać rodzaj wyjątku",
  "audit.object": "Obiekt",
  "audit.user": "Użytkownik",
  "audit.action": "Akcja",
  "audit.time.filter": "Filtr czasu",
  "audit.sort.by": "Sortuj według",
  audit: "Ścieżka audytu",
  "audit.module": "Moduł",
  "audit.times.stamp": "Znaczek czasu",
  "audit.search.logs": "Szukaj logów",
  "no.object.message": "Nie znaleziono obiektu",
  "no.user.message": "Nie znaleziono użytkownika",
  "no.module.message": "Nie znaleziono modułu",
  "no.action.message": "Nie znaleziono akcji",
  "no.time.filter.message": "Nie znaleziono filtra czasu",
  "no.sort.message": "Nie znaleziono sortowania",
  "fleet.user.delete.current": "Nie możesz usuwać grupy aktywnych użytkowników ani użytkowników z przypisanymi wyjątkami",
  "user.delete.current": "Nie możesz usuwać aktywnego użytkownika",
  "users.delete.has.exception": "Użytkownika mają przypisane wyjątki",
  "Split equally": "Podziel równo",
  deletedSuccessfullySingle: "{{objectNature}} {{ObjectName}} został pomyślnie usunięty.",
  deletedSuccessfullyDouble: "{{objectNature}} {{firstObjectName}} i {{secondObjectName}} zostały pomyślnie usunięte.",
  deletedSuccessfullyMultiple: "{{twoFirstObjectName}} i {{remainingCount}} inne {{objectNatureRemaining}} zostały pomyślnie usunięte.",
  userSingle: "użytkownik",
  userPlural: "użytkownicy",
  groupSingle: "group",
  groupPlural: "grupy",
  tagSingle: "tag",
  tagPlural: "tagi",
  orchestratorSingle: "orchestrator",
  orchestratorPlural: "orkiestratory",
  queueSingle: "queue",
  queuePlural: "kolejki",
  "time.zone": "Strefa czasowa",
  "profile.update-password.rewrite.email": "Przepisz e-mail",
  "profile.update-password.wrong.password": "Hasło nie pasuje",
  Preferences: "Preferencje",
  "user.information": "Informacje o użytkowniku",
  "change.your.password": "Zmień swoje hasło",
  "Tag already exist with the same name": "Tag już istnieje o tej samej nazwie.",
  "title.confirm.message.delete": "Usuwanie grupy",
  "items.pending": "Aż do",
  configuration: "Konfiguracja",
  "field.is.required": "{{field}} jest wymagane",
  "Setup Automatic Assignment": "Konfiguracja automatycznej alokacji",
  "Assignement Rules": "Zasady przydziału",
  "Automatic Exception Assignment": "Automatyczne przydzielanie wyjątków",
  "Rule Configuration": "Konfiguracja reguł",
  "Rule Name": "Nazwa reguły",
  "When an exception occurs for": "Gdy wystąpi wyjątek dla",
  "logical Connector": "Łącznik logiczny",
  "Item Data": "Dane elementu",
  Condition: "Warunek",
  Value: "Wartość",
  "Assign to": "Przypisz do",
  "User List": "Lista użytkowników",
  "Group List": "Lista grup",
  "Alert on assignment": "Alert przy przypisaniu",
  Summary: "Podsumowanie",
  "rules.already.exists.with.processes": "Reguła już istnieje z tymi samymi procesami. Czy chcesz usunąć starą regułę?",
  "confirm.delete.rule": "Czy na pewno chcesz usunąć tę regułę?",
  "confirm.disable.rule": "Czy na pewno chcesz wyłączyć tę regułę ?",
  "confirm.enable.rule": "Czy na pewno chcesz włączyć tę regułę ?",
  "When an exception occurs for details": "Użyj tej opcji, aby określić, które wyjątki powinny być automatycznie przypisane. Wybierz proces i zdefiniuj szczegóły wyjątków, które chcesz przypisać.",
  "Assign to details": "Tutaj możesz wybrać, czy przypisać wyjątki do określonego użytkownika lub grupy na podstawie zdefiniowanych przez Ciebie reguł. Dodatkowo zdecyduj, czy chcesz powiadomić przypisaną stronę o wyjątku.",
  "synchronized.processes": "Automatyzacja do synchronizacji",
  "max.processes.reached": "Osiągnięto maksymalną liczbę procesów",
  "process.in.orchestrator": "Automatyzacja w orkiestratorze",
  "process.synchronize": "Automatyzacja do synchronizacji",
  "orchestrator.formControl.processes.saveSuccess": "Pomyślnie dodano proces do synchronizacji",
  "orchestrator.formControl.processes.updateSuccess": "Pomyślnie zaktualizowano proces do synchronizacji",
  "task.initial.task": "Zadanie początkowe",
  "task.post.completion.delay": "Opóźnienie po zakończeniu",
  "task.remove": "Usuń zadanie",
  "task.name": "Nazwa zadania",
  "task.description": "Opis zadania",
  "task.on.complete": "Po zakończeniu",
  "task.on.exception": "W przypadku wyjątku",
  "task.add.session": "Dodaj sesję",
  "task.delete.session": "Usuń sesję",
  "task.name.already.exist": "Nazwa zadania już istnieje",
  "task(s)": "Zadanie/Zadania",
  "add.task": "Dodaj zadanie",
  task: "Zadanie",
  "no.tasks.yet": "Brak zadań",
  "no.task.message": "Nie znaleziono zadania",
  "task.stop": "Zatrzymaj",
  "task.abort": "Przerwij",
  "task.required.process": "Proszę wybrać proces",
  "task.required.first.session.process": "Proszę wybrać proces pierwszej sesji",
  "process.migration.not.possible": "Następujące automatyzacje są już przypisane do innych SRQ. Automatyzacja może być przypisana tylko do jednego SRQ.",
  "see.all": "Zobacz wszystko",
  "sync.task_session": "Sesje zadań",
  "sync.sch_task": "Zadania",
  "unique.schedule.name.exception": "Nazwa harmonogramu już istnieje",
  "awaiting.synchro": "Oczekiwanie na synchronizację",
  "pendingCount.descending": "Elementy oczekujące (malejąco)",
  pendingCount: "Elementy oczekujące",
  "completedCount.descending": "Ukończone elementy (malejąco)",
  completedCount: "Ukończone elementy",
  "exceptionCount.descending": "Elementy wyjątkowe (malejąco)",
  exceptionCount: "Elementy wyjątkowe",
  "productivity.descending": "Produktywność (malejąco)",
  "occupancy.ascending": "Zajętość",
  "occupancy.descending": "Zajętość (malejąco)",
  "processRestriction.recentActivity": "Ostatnia aktywność",
  "processRestriction.recentActivity.descending": "Ostatnia aktywność (malejąco)",
  "average.FTEs.saved": "Średni zaoszczędzony czas",
  "success.rate": "Wskaźnik sukcesu",
  "average.success.rate": "Średni wskaźnik sukcesu",
  transactions: "Transakcje",
  "total.transactions": "Całkowita liczba transakcji",
  "average.transactions": "Średnia liczba transakcji",
  min: "min",
  week: "tydzień",
  "newDashboard.AllStats.title": "Statystyki wszech czasów",
  "newDashboard.CurrentStats.title": "Aktualne statystyki",
  "transactions.header.tooltip": "Całkowita liczba transakcji",
  "successRate.header.tooltip": "Wszystki czas procentowy udanych transakcji",
  "Completed transactions": "Zakończone transakcje",
  "bots.header.tooltip": "Całkowity czas pracy botów",
  "FTESaved.header.tooltip": "Całkowita liczba oszczędzonych FTE",
  "transactions.header.label": "Wszystkie transakcje",
  "items.header.label": "Wszystkie elementy",
  "successRate.header.label": "Wskaźnik sukcesu",
  "transactions.cardHeader": "Transakcje",
  "AvgHandlingTime.cardHeader": "Średni czas obsługi",
  "inProgress.transactions.status": "W toku",
  "completed.transactions.status": "Zakończono",
  "all.processes": "Automatyka główne",
  "working.processes.status": "Zaplanowane - Działa",
  "notWorking.processes.status": "Zaplanowane - Nie działa",
  "Processes.activity.tooltip": "To KPI śledzi liczbę procesów, które są zgodne z planem i są realizowane zgodnie z planem w porównaniu do liczby procesów, które są zaplanowane, ale nie przebiegają zgodnie z planowanym harmonogramem.",
  "til.now": "Aż do teraz",
  "Monitoring Dashboard": "Panel monitorowania",
  Orchestrators: "Orkiestratorzy",
  Resources: "Zasoby",
  "System exceptions": "Wyjątki systemowe",
  "Business exceptions": "Wyjątki biznesowe",
  "reporting.exportCSV": "Eksportuj jako CSV",
  "reporting.exportCSV.all": "Eksportuj wszystko jako CSV",
  migrate: "Migruj",
  "do.not.migrate": "Nie migruj",
  "The name of the schedule": "Nazwa harmonogramu",
  "The first task executed by the schedule": "Pierwsze zadanie wykonane przez harmonogram",
  "The date and time of the first execution of the schedule": "Data i godzina pierwszego wykonania harmonogramu",
  "The date and time after which the schedule will no longer be executed": "Data i godzina po których harmonogram przestanie być wykonywany",
  "The frequency at which the schedule will be executed (starting from the start date)": "Częstotliwość, z jaką harmonogram będzie wykonywany (rozpoczynając od daty początkowej)",
  "Set(s) of process sessions that are executed concurrently": "Zbiór(y) sesji procesów wykonywanych równocześnie",
  "The task to execute if this task is completed": "Zadanie do wykonania, jeśli to zadanie zostanie zakończone",
  "The task to execute if this task ends on an exception": "Zadanie do wykonania, jeśli to zadanie kończy się wyjątkiem",
  "The process to be executed in this task session": "Automatyzacja do wykonania w tej sesji zadania",
  "The resource (robot on prod) on which the process will be executed": "Zasób (robot na produkcji), na którym będzie wykonywany proces",
  GROUP_ADDED: "Dodana grupa",
  GROUP_MODIFIED: "Zmodyfikowane informacje o grupie",
  GROUP_DELETED: "Usunięta grupa",
  USER_ADDED: "Użytkownik dodany",
  USER_INFO_MODIFIED: "Informacje o użytkowniku zostały zmodyfikowane",
  USER_DELETED: "Użytkownik usunięty",
  USER_PERMISSIONS_MODIFIED: "Zmodyfikowano uprawnienia użytkownika",
  PROCESS_EXCEPTION_HIDDEN: "wyjątek procesu został ukryty",
  PROCESS_EXCEPTION_UNHIDDEN: "został pokazany wyjątek procesu",
  DEFAULT_ALERT_CREATED: "Utworzono alert domyślny",
  DEFAULT_ALERT_MODIFIED: "Zmodyfikowano alert domyślny",
  DEFAULT_ALERT_DELETED: "Domyślny alert został usunięty",
  CUSTOM_ALERT_CREATED: "Utworzono alert niestandardowy",
  CUSTOM_ALERT_MODIFIED: "Zmodyfikowano alert niestandardowy",
  CUSTOM_ALERT_DELETED: "Alert niestandardowy został usunięty",
  TAG_CREATED: "Znacznik został utworzony",
  TAG_DELETED: "Znacznik usunięty",
  TAG_ASSIGNED_PROCESSES: "Automatyka przypisane do tagu",
  TAG_UNASSIGNED_PROCESSES: "Automatyka wycofane z tagu",
  USER_ASSIGNED_PROCESSES: "Automatyka zostały przypisane do użytkownika",
  USER_UNASSIGNED_PROCESSES: "Automatyka wycofane od użytkownika",
  USER_ASSIGNED_TAGS: "Tagi zostały przypisane do użytkownika",
  USER_UNASSIGNED_TAGS: "Tagi wycofane od użytkownika",
  PROCESS_SLA_UPDATED: "Zaktualizowano umowy SLA procesu",
  PROCESS_SETTINGS_UPDATED: "Zaktualizowano ustawienia procesu",
  PROCESS_DATA_MAPPING_UPDATED: "Zaktualizowano ustawienia mapowania danych",
  RESOURCE_ASSIGNED_PROCESSES: "Automatyka przypisane do zasobu",
  RESOURCE_UNASSIGNED_PROCESSES: "Automatyka wycofane z zasobu",
  RESOURCE_INFORMATION_MODIFIED: "Zmodyfikowano informacje o zasobach",
  RESOURCE_HIDDEN: "Zasób został ukryty",
  RESOURCE_UNHIDDEN: "Zasób został wyświetlony",
  COST_ALLOCATION_ADDED: "Koszt dodany",
  COST_ALLOCATION_DELETED: "Koszt usunięty",
  COST_ALLOCATION_EDITED: "Koszt zmodyfikowany",
  PROCESS_PARAMETERS_EDITED: "Zmodyfikowano parametry procesu",
  DB_CONNECTION_INITIATED: "Rozpoczęto połączenie z bazą danych",
  ORCHESTRATOR_ADDED: "Dodał orkiestrator",
  ORCHESTRATOR_MODIFIED: "Zmodyfikowano informacje o programie Orchestrator",
  ORCHESTRATOR_ENABLED: "Orchestrator włączony",
  ORCHESTRATOR_DISABLED: "Orkiestrator wyłączony",
  ORCHESTRATOR_DELETED: "Orkiestrator usunięty",
  ANALYTICS_PRESET_CREATED: "Utworzono gotowe ustawienia Analytics",
  ANALYTICS_PRESET_DELETED: "Predefiniowane ustawienia Analytics zostały usunięte",
  CUSTOM_KPI_DELETED: "Usunięto niestandardowy KPI",
  CUSTOM_KPI_CREATED: "Utworzono niestandardowy KPI",
  CUSTOM_KPI_MODIFIED: "Zmodyfikowano niestandardowe informacje o KPI",
  QUEUE_ASSIGNED_RESOURCE: "Zasób przypisany do kolejki SR",
  QUEUE_UNASSIGNED_RESOURCE: "Zasób wycofany z kolejki SR",
  QUEUE_ASSIGNED_PROCESS: "Automatyzacja przypisany do kolejki SR",
  QUEUE_UNASSIGNED_PROCESS: "Automatyzacja wycofany z kolejki SR",
  QUEUE_CREATED: "Utworzono kolejkę SR",
  QUEUE_MODIFIED: "Zmodyfikowano informacje o kolejce SR",
  QUEUE_DELETED: "Kolejka SR została usunięta",
  "A role with the same already exists": "Rola o takiej nazwie już istnieje",
  "Update Role": "Aktualizuj rolę",
  "Role Name": "Nazwa roli",
  "Apply to this group": "Ustawiona jako domyślna rola dla następujących grup",
  "Select Permissions": "Wybierz uprawnienia",
  "Manage Roles": "Rolami",
  Role: "Rola",
  "default.group.role": "Domyślna rola grupy",
  "group.change.role": "Zmiana domyślnej roli grupy spowoduje zastosowanie uprawnień do wszystkich jej użytkowników, którzy są ustawieni na dziedziczenie uprawnień tej grupy",
  "Create Role": "Utwórz rolę",
  "This role has associated users": "Ta rola jest powiązana z użytkownikami.",
  assign: "przypisanie",
  unassign: "nieprzypisanie",
  "user.permission.paste.error": "Upewnij się, że najpierw skopiujesz uprawnienia",
  "user.permission.paste": "Wklej uprawnienia",
  "user.permission.copy": "Kopiuj uprawnienia",
  "copy.role.permissions.template": "Skopiuj szablon uprawnień roli",
  "Permissions are required": "Wymagane są uprawnienia",
  "Role Configuration": "Konfiguracja roli",
  "user.add.inherit.group.permissions": "Dziedzicz uprawnienia grupy",
  Interfaces: "Interfejsy",
  Automations: "Automatyzacje",
  "Opportunity assessment": "Możliwości",
  "Personal settings": "Ustawienia osobiste",
  "List of item Data for this process": "Lista danych elementów dla tego procesu",
  "DASHBOARD Today": "Dziś",
  "DASHBOARD This Week": "Ostatnie 7 dni",
  "DASHBOARD This Month": "Ostatnie 30 dni",
  "DASHBOARD This Year": "Ostatnie 365 dni",
  "Last session synched": "Ostatnia zsynchronizowana sesja",
  "The number of resources linked to this orchestrator": "Liczba zasobów powiązanych z tym orchestratorem",
  "The number of automations linked to this orchestrator": "Liczba automatyzacji powiązanych z tym orchestratorem",
  "Number of licences allowed to use on this orchestrator": "Liczba licencji dozwolonych do użycia na tym orchestratorze",
  "The date of the latest session synched by SmartRoby": "Data ostatniej zsynchronizowanej sesji przez SmartRoby",
  "The number of users belonging to this group": "Liczba użytkowników należących do tej grupy",
  "The number of automations assigned to this group": "Liczba automatyzacji przypisanych do tej grupy",
  "Resource type": "Typ zasobu",
  "Paired automations": "Sparowane automatyzacje",
  "Automation dependency": "Zależność automatyzacji",
  "The number of automations that this resource is able to run": "Liczba automatyzacji, które ten zasób może uruchomić",
  "The number of automations already scheduled with this resource": "Liczba już zaplanowanych automatyzacji z tym zasobem",
  "Created since": "Utworzone od",
  "Human-in-the-loop activated": "Aktywacja trybu człowiek-w-pętli",
  "kpi.parameter.allowScheduling.tooltip": "Umożliwia zaplanowanie automatyzacji za pomocą SmartRoby",
  "kpi.parameter.allowManual.tooltip": "Umożliwia ręczne uruchamianie automatyzacji za pomocą SmartRoby",
  "kpi.parameter.parameter.retryable.tooltip": "Umożliwia użytkownikom ponowienie próby obsługi wyjątków dla tej automatyzacji",
  "kpi.parameter.human.in.the.loop.activated.tooltip": "Umożliwia użytkownikom uzupełnienie lub zmianę danych transakcyjnych przed ponowną próbą elementu dla tej automatyzacji",
  "KPI settings": "Ustawienia KPI",
  "SR properties": "Właściwości SR",
  "Assignment Rules": "Zasady przydziału",
  "You choose a process assigned to queue, all processes must be assigned to the same queue": "Wybrano proces przypisany do kolejki, wszystkie automatyka muszą być przypisane do tej samej kolejki",
  "Report a Bug": "Zgłoś błąd",
  "executions found": "znaleziono wykonania",
  "licence.max.automations": "Maksymalne automatyzacje",
  "Inherit group automations": "Dziedzicz automatyzacje grupowe",
  "master.process": "Proces główny",
  SCHEDULED_ON_COMPLETE: "zaplanowane po zakończeniu",
  SCHEDULED_ON_EXCEPTION: "programado en caso de excepción",
  "File exported successfully": "Plik pomyślnie wyeksportowany",
  "allocated.priority": "Przydzielony priorytet (SLA)",
  "Edit Resources": "Edycja zasobów",
  QUEUE_ASSIGNED_ROBOT: "Zasób przypisany do kolejki",
  SCHEDULE_ADDED: "Dodano harmonogram",
  ITEM_EXCEPTION_MARKED_DONE: "Wyjątek elementu oznaczony jako zakończony",
  ITEM_EXCEPTION_RETRIED: "Wyjątek elementu ponownie próbowany",
  PROCESS_STARTED: "Proces rozpoczęty",
  SCHEDULE_MODIFIED: "Harmonogram zmieniony",
  SCHEDULE_DELETED: "Harmonogram usunięty",
  EXCEPTION_RULE_MODIFIED: "Zasada wyjątku zmodyfikowana",
  EXCEPTION_RULE_ADDED: "Zasada wyjątku dodana",
  ITEM_EXCEPTION_RELEASED: "Wyjątek elementu zwolniony",
  EXCEPTION_RULE_DELETED: "Zasada wyjątku usunięta",
  QUEUE_UNASSIGNED_ROBOT: "Zasób odłączony od kolejki",
  ITEM_EXCEPTION_ASSIGNED: "Wyjątek elementu przypisany",
  EXCEPTION_RULE_DISABLED: "Zasada wyjątku wyłączona",
  "alerts.management.smsStatus.label": "SMS",
  "alerts.management.emailStatus.label": "Email",
  "Launched with input": "Uruchomiono z wejściem",
  "Items processed": "Przetworzone elementy",
  "Items Exceptions can be retried with original": "Wyjątki elementów można ponownie próbować z oryginałem",
  "Item Exceptions can be retried with different data": "Wyjątki elementów można ponownie próbować z różnymi danymi",
  "Available robots": "Dostępne roboty",
  "Robot assignation": "Przypisanie robota",
  Time: "Czas",
  "Estimated execution time": "Szacowany czas wykonania",
  "Cost distribution": "Rozkład kosztów",
  "enhanced.search.tooltip": "Ulepsz swoje wyszukiwanie za pomocą operatorów logicznych: użyj 'AND', aby znaleźć projekty RPA spełniające wiele kryteriów (np. 'Automatyzacja AND Finanse'), 'OR', aby odkryć projekty w jednej z dwóch dziedzin (np. 'Finanse OR HR'), oraz 'NOT', aby wykluczyć pewne tematy z wyników (np. 'Automatyzacja NOT Ręczne').",
  "resources.formSection.orsInfo": "Informacje ORS",
  "resource.management.formControl.orsResourceVmIp": "IP VM zasobu",
  "resource.management.formControl.orsScreenHeight": "Wysokość",
  "resource.management.formControl.orsScreenWidth": "Szerokość",
  "resource.management.formControl.activateOrs": "Aktywuj konfigurację ORS",
  "resource.management.formControl.orsScreenDpi": "DPI",
  "ors.connection.lost": "Połączenie nie znalezione lub zamknięte",
  "enable.ors": "Proszę włączyć ors, aby monitorować swoją sesję",
  "page.of": "Strona {{page}} z {{pages}}",
  "orchestrator.management.formControl.irisUsername": "Nazwa użytkownika Iris",
  "orchestrator.management.formControl.irisPassword": "Hasło Iris",
  "orchestrator.management.formControl.irisUrl": "Adres URL Iris",
  "confirm.delete.role": "Czy na pewno chcesz usunąć tę rolę?",
  "Monitoring & Reporting": "Monitorowanie i raportowanie",
  "Automation Management": "Zarządzanie automatyzacją",
  "Resource Management": "Zarządzanie zasobami",
  "days.to.license.expiry": "Masz {{daysToExpiry}} dni do wygaśnięcia licencji",
"license.max.automations.reached": "Osiągnąłeś maksymalną liczbę automatyzacji dozwoloną przez twoją licencję, nie będziesz mógł synchronizować więcej automatyzacji",
"license.max.resources.reached": "Osiągnąłeś maksymalną liczbę zasobów dozwoloną przez twoją licencję, nie będziesz mógł synchronizować więcej zasobów",
"orchestrator.management.formControl.uipath.is.cloud": "Chmurowy orkiestrator UiPath",
"orchestrator.management.formControl.uipath.is.cloud.information": "Wskazuje, czy orkiestrator jest chmurowym orkiestratorem UiPath, w przeciwnym razie jest to orkiestrator samodzielny",
"orchestrator.management.formControl.uipath.url": "URL orkiestratora",
"orchestrator.management.formControl.uipath.url.information": "To jest URL samodzielnego orkiestratora, będzie zawierać domenę, na której jest hostowany orkiestrator"
};
