import { Duration } from "util/constants";
import { subMonths, subWeeks, subYears } from "date-fns";
import { format } from "date-fns-tz";
import { formtNumberByLanguage, getDefaultFormat } from "util/helpers/number.helpers";

export const handleInputChanges = (
    e,
    isFloatNumber = true,
    inputLength = undefined,
) => {
    let inputData = String(e.target.value);
    const countPoint = inputData.length - inputData.replaceAll(".", "").length;
    const point = !isFloatNumber || countPoint > 1 ? "" : ".";
    if (inputLength && inputData.length > inputLength) {
        inputData = inputData.slice(0, 9);
    }
    const lastElem = inputData[inputData.length - 1];
    if (Number(lastElem) !== 0 && !Number(lastElem) && lastElem !== point) {
        inputData = inputData.slice(0, -1);
    }
    e.target.value = inputData;
};
export const REGEX = {
    NAME: /^[a-zA-ZÀ-ÿ'.[`~!@#$%^&*()_\-|+=÷¿?;:'",<>{}/\\\]\s]{0,25}$/,
    EMAIL: /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9-_][a-zA-Z0-9-_.]*\.[a-zA-Z]{2,}$/,
    TEL: /^\+?[0-9]{10,18}$/,
    LINKEDIN:
        /(ftp|http|https):\/\/?(?:www\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!-]))?/,
    FUNCTION: /.{0,150}/,
    AMOUNT: /^([0-9 ]{0,12}(\.,?[0-9]{0,2})?)$/,
    FAX: /^(\+?\d{1,}(\s?|-?)\d*(\s?|-?)\(?\d{2,}\)?(\s?|-?)\d{3,}\s?\d{3,})$/,
    // eslint-disable-next-line max-len
    IP: /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$|^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))$|^$/,
    SIRET: /^\d{14}$/,
    SIREN: /^\d{9}$/,
    ALPHANUM: /^[a-zA-Z0-9]+$/,
    POSITIVE_INTEGER: /^\d+$/,
    NUMBER: /^[+-]?\d+(\.\d+)?$/

};
export const handleDisplayedLabel = (label, keepCaseLabel = false) => {
    if (label && keepCaseLabel) {
        return label;
    }
    // eslint-disable-next-line max-len, no-unsafe-optional-chaining
    return label ? label?.charAt(0).toUpperCase() + label?.slice(1).toLowerCase() : ""
};
export const isValidSiren = (sirenValue) => {
    if (!sirenValue || sirenValue?.length === 0) return true;
    if (sirenValue.length !== 9 || isNaN(sirenValue)) return false;
    const array = Array.from(sirenValue, (num) => Number(num)).map((e, index) => (index % 2 === 1 ? e * 2 : e * 1));
    const arrayFinal = array.map((e) => (e >= 10
        ? Array.from(e.toString(), (num) => Number(num)).reduce(
            (a, b) => a + b,
            0,
        )
        : e));
    const sum = arrayFinal.reduce((a, b) => a + b, 0);
    if (sum % 10 === 0) return true;
    return false;
};
export const isValidSiret = (siretValue) => {
    let isValid;
    if (!siretValue || siretValue?.length === 0) return true;
    if (siretValue.length !== 14 || isNaN(siretValue)) isValid = false;
    else {
        // le SIRET est un numérique à 14 chiffres
        // Les 9 premiers chiffres sont ceux du SIREN (ou RCS), les 4 suivants correspondent au numéro d'établissement
        // et enfin le dernier chiffre est une clef de LUHN.
        let somme = 0;
        let tmp;
        // eslint-disable-next-line no-plusplus
        for (let cpt = 0; cpt < siretValue.length; cpt++) {
            if (cpt % 2 === 0) {
                // Les positions impaires : 1er, 3è, 5è, etc...
                tmp = siretValue.charAt(cpt) * 2; // Position paire >> On le multiplie par 2
                if (tmp > 9) tmp -= 9; // Si le résultat est supérieur à 9, on lui soustrait 9
            } else tmp = siretValue.charAt(cpt); // Position impaire
            somme += parseInt(tmp);
        }
        if (somme % 10 === 0) isValid = true; // Si la somme est un multiple de 10 alors le SIRET est valide
        else isValid = false;
    }
    return isValid;
};
export const selectDurationValue = (
    selectedDuration,
    setToDate,
    setshowCustomDate,
    setFromDate,
    // eslint-disable-next-line default-param-last
    handleChangeDates = null,
    dateFormat,
) => {
    if (selectedDuration !== "CUSTOM") {
        const now = new Date();
        let toDate = now;
        let fromDate;
        switch (selectedDuration) {
            case Duration.DAY:
                fromDate = toDate;
                break;
            case Duration.WEEK:
                fromDate = subWeeks(now, 1);
                fromDate.setDate(fromDate.getDate() + 1);
                break;
            case Duration.MONTH:
                fromDate = subMonths(now, 1);
                fromDate.setDate(fromDate.getDate() + 1);
                break;
            case Duration.YEAR:
                fromDate = subYears(now, 1);
                fromDate.setDate(fromDate.getDate());
                break;
            case Duration.ALL_TIME:
                fromDate = null;
                toDate = null;
                break;
            default:
                break;
        }
        toDate = toDate
            ? format(toDate.setHours(23, 59, 59, 999), dateFormat)
            : null;
        fromDate = fromDate
            ? format(fromDate.setHours(0, 0, 0, 0), dateFormat)
            : null;
        setToDate(toDate);
        setFromDate(fromDate);
        if (setshowCustomDate) setshowCustomDate(false);
        if (handleChangeDates) {
            handleChangeDates(fromDate, toDate, selectedDuration);
        }
    } else {
        const now = new Date();
        setToDate(format(now, dateFormat));
        if (setshowCustomDate) setshowCustomDate(true);
        setFromDate(format(now, dateFormat));
        if (handleChangeDates) {
            handleChangeDates(now, now, selectedDuration);
        }
    }
};
export const bePeriods = [
    { label: "fleet.management.be.period.1", days: 30 },
    { label: "fleet.management.be.period.2", days: 90 },
    { label: "fleet.management.be.period.3", days: 180 },
    { label: "fleet.management.be.period.4", days: 360 },
];
export const formatNumberInputOnBlur = (e) => {
    const value = getDefaultFormat(e.target.value);
    const newValue = formtNumberByLanguage(value);
    e.target.value = newValue;
};
export const formatNumberInputOnFocus = (e) => {
    const newValue = getDefaultFormat(e.target.value);
    e.target.value = newValue;
};
